import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  @Input() text?: string;
  @Input() title: string;
  @Input() applyEdit?: string = 'Да';
  @Input() cancelEdit?: string = 'Нет';

  @Output() onClose = new EventEmitter<boolean>();

  constructor(private readonly activeModal: NgbActiveModal) {}

  cancel(): void {
    this.onClose.emit(false);
    this.activeModal.dismiss();
  }

  submit(): void {
    this.onClose.emit(true);
    this.activeModal.dismiss();
  }
}
