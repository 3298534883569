<div class="contacts">
  <div class="d-flex align-items-center contacts-left">
    <button [class.disabled]="!contacts.length" class="icon-button chat-expansion__caret" (click)="onContactsExpand()">
      <app-svg-icon [name]="isContactsExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
    </button>
    <div class="contact-title">
      <span>Контакты</span>
    </div>
    <div
      class="contacts__filters"
      *ngIf="
        chatSectionSelected.name === chatSectionsEnum.ADMIN ||
        chatSectionSelected.name === chatSectionsEnum.HOLDING ||
        chatSectionSelected.name === chatSectionsEnum.TRADE ||
        (chatSectionSelected.name === chatSectionsEnum.TECH && techType !== techTypeEnum.MY_TECH)
      "
      [class.invisible]="chatSectionSelected.name === chatSectionsEnum.TRADE"
    >
      <button class="icon-button d-flex align-items-center" (click)="onFilterToggle()">
        <div class="sort__button mr-1">
          <app-svg-icon [name]="'sort-amount-down'" width="12" height="12"></app-svg-icon>
        </div>
        <div class="sort__button"></div>
      </button>
    </div>
    <button *ngIf="techType !== techTypeEnum.MY_TECH" class="icon-button">
      <app-svg-icon [name]="'filter-table'" width="12" height="12"></app-svg-icon>
    </button>
  </div>

  <div class="d-flex align-items-center contacts-icon" [style.marginLeft]="scrollPosition + 'px'">
    <button class="icon-button">
      <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
    </button>
    <span
      class="chat-number"
      [style.visibility]="totalUnreadCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadCounter === '10+'"
      >{{ totalUnreadCounter }}</span
    >
  </div>
</div>
<div class="contacts-list" *ngIf="isContactsExpanded">
  <!-- Отсылка к старому коду с сортировками для таски фильтров  -->
  <!-- <div *ngFor="let contact of contacts | contactsFilter : contactsOnlyFilter | usersInitialsFilter : filterString"></div> -->
  <ng-container *ngIf="chatAdminView">
    <div *ngFor="let contact of contacts">
      <app-chat-contact [contact]="contact"></app-chat-contact>
    </div>
  </ng-container>
  <ng-container *ngIf="!chatAdminView && treeContacts.length">
    <div *ngFor="let contact of treeContacts">
      <app-chat-contact [isTree]="true" [contact]="contact"></app-chat-contact>
    </div>
  </ng-container>
</div>
