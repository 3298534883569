import { Column, PaymentRegistryRequestConfig } from '@app/+tariffs/models/payment-registry-column.model';
import { FilterPayment, FilterPaymentRegister } from '@app/+tariffs/models/payment-state.model';

export class RegistersHelper {
  static hasColumnFilterValue(
    column: Column<PaymentRegistryRequestConfig>,
    filterData: FilterPaymentRegister
  ): boolean {
    if (!filterData?.filters?.length && !Object.keys(filterData?.extra || {}).length) {
      return false;
    }

    if (!column?.filterConfig || typeof column.filterConfig !== 'object') {
      return false;
    }

    for (const key of Object.keys(column.filterConfig)) {
      if (key === 'filter') {
        if (column.filterConfig.filter) {
          const filterMatch = filterData.filters.some((filterColumn) => filterColumn.name === column.id);
          if (filterMatch && column.filterConfig.filter.val?.length) {
            return true;
          }
        }
      } else {
        return this.hasMatchingField(filterData.extra, column.filterKeys);
      }
    }

    return false;
  }

  static hasMatchingField(extra: Record<string, any>, filterKeys: string[]): boolean {
    if (!extra || typeof extra !== 'object' || !filterKeys || !Array.isArray(filterKeys)) {
      return false;
    }

    return filterKeys.some((key) => extra.hasOwnProperty(key) && !!extra[key]);
  }

  static writeColumnFilterValue(
    column: Column<PaymentRegistryRequestConfig>,
    filterData: FilterPaymentRegister
  ): Record<string, any> | undefined {
    if (!column?.filterConfig) {
      return column?.filterConfig;
    }

    if (!filterData?.filters?.length) {
      return column.filterConfig;
    }

    if (Object.keys(column.filterConfig).includes('filter')) {
      filterData.filters.forEach((filterColumn) => {
        if (column.filterKeys?.includes(filterColumn.name) && filterColumn.filter?.val !== undefined) {
          column.filterConfig[filterColumn.name] = filterColumn.filter.val;
        } else if (column.filterConfig.filter && filterColumn.name === column.id) {
          column.filterConfig.filter = filterColumn.filter;
        }
      });
    } else {
      Object.keys(filterData.extra).forEach((payloadKey) => {
        if (filterData.extra[payloadKey] && column.filterKeys.includes(payloadKey)) {
          column.filterConfig[payloadKey] = filterData.extra[payloadKey];
        }
      });
    }

    return column.filterConfig;
  }

  static getNewFilter(stateFilter: FilterPaymentRegister, filters: FilterPaymentRegister) {
    const filtersFromPayload = filters.filters || [];
    const extraFromPayload = filters.extra || {};

    if (filtersFromPayload?.length > 0) {
      filtersFromPayload.forEach((filter) => {
        const existsInState = stateFilter.filters.some((registryFilter) => registryFilter.name === filter.name);

        if (!existsInState) {
          stateFilter.filters.push(filter);
        }
      });
    }

    Object.keys(extraFromPayload).forEach((payloadKey) => {
      const value = extraFromPayload[payloadKey];
      if (value !== null) {
        stateFilter.extra[payloadKey] = value;
      } else {
        delete stateFilter.extra[payloadKey];
      }
    });

    return stateFilter;
  }
}
