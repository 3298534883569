<app-rich-modal (close)="onChatClose()" class="chat">
  <div class="chat__loader" *ngIf="chatLoading$ | async">
    <app-svg-icon [height]="64" [width]="64" name="loader-animate" class="chat__loader-icon"></app-svg-icon>
  </div>
  <!-- иконки перемещения и закрепления раскоментить\заменить при реализации функционала -->
  <!-- <div class="top-icon">
    <button class="icon-button">
      <app-svg-icon name="move" width="16" height="16"></app-svg-icon>
    </button>
    <button class="icon-button">
      <app-svg-icon name="pin" width="16" height="16"></app-svg-icon>
    </button>
  </div> -->
  <div class="content">
    <div class="left" [style.width]="isContactsHidden ? '0px' : ''">
      <div [ngClass]="isContactsHidden ? 'hidden' : ''">
        <app-chat-section-select></app-chat-section-select>
      </div>
      <div class="filter">
        <div [ngClass]="isContactsHidden ? 'hidden' : ''">
          <app-chat-contacts-filter></app-chat-contacts-filter>
        </div>
      </div>
      <div
        class="groups"
        [style.overflowX]="
          chatSectionSelected.name !== chatSectionsEnum.ADMIN && chatSectionSelected.name !== chatSectionsEnum.TRADE
            ? 'auto'
            : 'hidden'
        "
        [style.maxWidth]="isContactsHidden ? '310px' : ''"
        #groups
        (scroll)="scrollEvent(groups)"
      >
        <div
          [ngClass]="isContactsHidden ? 'hidden' : ''"
          *ngIf="
            chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.TRADE
          "
        >
          <app-chat-groups></app-chat-groups>
          <app-chat-contacts
            *ngIf="
              chatSectionSelected.name !== chatSectionsEnum.TECH &&
              chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO &&
              chatSectionSelected.name !== chatSectionsEnum.TRADE
            "
          ></app-chat-contacts>
        </div>
        <div
          class="chat__tech"
          [ngClass]="isContactsHidden ? 'hidden' : ''"
          *ngIf="chatSectionSelected.name === chatSectionsEnum.TECH"
        >
          <app-tech-chat></app-tech-chat>
        </div>

        <div [ngClass]="isContactsHidden ? 'hidden' : ''" *ngIf="chatSectionSelected.name === chatSectionsEnum.TRADE">
          <app-trade-chat></app-trade-chat>
        </div>
      </div>
    </div>
    <div class="center">
      <button
        class="icon-button"
        [class]="!isContactsHidden ? 'icon-button-left' : ''"
        (click)="$event.stopPropagation(); toggleContactsHidden()"
      >
        <app-svg-icon [name]="isContactsHidden ? 'right' : 'left'" width="6" height="10"></app-svg-icon>
      </button>
    </div>
    <div class="right">
      <app-chat-tabs></app-chat-tabs>
      <ng-container *ngIf="contactSelected.room_id; else noGroupSelected">
        <div class="chat-messages">
          <router-outlet></router-outlet>
        </div>
        <div>
          <app-chat-input *ngIf="!isTradeGroup(contactSelected)"></app-chat-input>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- popup for editing group -->
  <app-modal-window
    [isShowed]="isEditingGroupOrTheme"
    (closeEvent)="closeEditingGroupOrTheme()"
    centered="true"
    customClass="edit-group-modal"
  >
    <app-chat-group-or-theme-edit *ngIf="isEditingGroupOrTheme"></app-chat-group-or-theme-edit>
  </app-modal-window>
  <!-- popup for editing group end -->
  <!-- popup for editing group -->
  <!-- ФИЛЬТРЫ ТОЖЕ ОБОРАЧИВАЕМ В МОДАЛКУ! -->
  <app-chat-contacts-only-filter
    *ngIf="
      isEditingContactsOnlyFilter &&
      (chatSectionSelected.name === chatSectionsEnum.ADMIN ||
        chatSectionSelected.name === chatSectionsEnum.HOLDING ||
        chatSectionSelected.name === chatSectionsEnum.DUTY_TSO)
    "
  ></app-chat-contacts-only-filter>
</app-rich-modal>

<ng-template #noGroupSelected>
  <div class="no-group-select">Нет открытых каналов</div>
</ng-template>
