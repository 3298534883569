import { ChatService } from '@app/chat/services/chat.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SocketDataService } from '@app/services/socket-data.service';
import { DestroyService } from '@app/services/destroy.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-tab',
  templateUrl: './chat-tab.component.html',
  styleUrls: ['./chat-tab.component.scss'],
  providers: [DestroyService],
})
export class ChatTabComponent implements OnChanges {
  @Input() tab: any;
  @Input() active: boolean = false;
  @Input() isDragging: boolean;
  @Output() isDraggingChange = new EventEmitter<boolean>();

  constructor(
    private readonly elRef: ElementRef,
    private readonly chatService: ChatService,
    private readonly chatDataService: SocketDataService,
    private readonly router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active && changes.active.currentValue === true) {
      this.chatService.scrollMeToTab(this.elRef);
    }
  }

  onTabSelect() {
    if (this.isDragging) {
      this.isDraggingChange.emit(false);
      return;
    }

    this.chatService.setRoomSelected(this.tab);
    this.router.navigate([{ outlets: { popup: ['chat', this.tab.room_id] } }]);
  }

  getTabTitle(): string {
    if (this.tab.group_id) {
      return `Тема ${this.tab.title}`;
    }
    if (this.tab.title && !this.tab.group_id) {
      return `Группа ${this.tab.title}`;
    }

    const fullName = `${this.tab.second_name || ''} ${this.tab.first_name || ''}`.trim();
    return fullName || this.tab.email || 'Без имени';
  }

  onTabRemove($event: Event) {
    $event.stopPropagation();
    this.chatService.clearContactSelected();
    this.chatService.deleteTab(this.tab);
    this.chatService.goToRoot();
    const messagesIds = this.chatService.getMessagesRoomStore(this.tab.room_id).list.map((message) => message.id) || [];

    if (this.tab.counter > 0) {
      this.chatDataService.setMessageRead(this.tab.room_id, messagesIds);
    }
  }
}
