import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserCompetenceMapModalComponent } from '@app/+competence-map/competence-shared/user-competence-map/user-competence-map-modal/user-competence-map-modal.component';
import { AccessRights } from '@app/+trades/constants/trades.constants';
import { AccessRight } from '@app/+trades/types/trades.types';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatSection, ChatUserTreeWithNode } from '@app/chat/models/chat.model';
import { ChatService } from '@app/chat/services/chat.service';
import { FileManagerModalUserInfoComponent } from '@app/file-manager/components/file-manager-modal-user-info/file-manager-modal-user-info.component';
import { DestroyService } from '@app/services/destroy.service';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { User } from '@app/shared/models/user.model';
import { InitialsPipe } from '@app/shared/pipes/users/initials.pipe';
import { PopoverService } from '@app/shared/services/popover.service';
import { UserTypes } from '@app/shared/types/user.types';
import { TreeNode } from '@circlon/angular-tree-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat-user-item',
  templateUrl: './chat-user-item.component.html',
  styleUrls: ['./chat-user-item.component.scss'],
})
export class ChatUserItemComponent implements OnInit {
  private _item: ChatUserTreeWithNode = {} as ChatUserTreeWithNode;

  @Input()
  set item(value: ChatUserTreeWithNode) {
    this._item = value;
    this.roomId = value.room_id;
  }

  get item(): ChatUserTreeWithNode {
    return this._item;
  }

  @Input() node: TreeNode;

  @Input() userId: number;
  @Input() ownerId: number;
  @Input() userType: UserTypes;
  @Input() isAccessChangeOptionsShowed = true;
  @Input() isGroup: boolean = false;
  @Input() withoutSelection: boolean = false;
  @Input() searchString: string;
  @Input() isRightPart: boolean = false;

  @Input() placementIcons: 'top' | 'right' = 'right';

  rolesEnum = RolesEnum;

  isAccessSelectOpen = false;
  accessRights = AccessRights;
  roomId: string | number;

  chatSectionsEnum = ChatSectionsEnum;
  chatSectionSelected: ChatSection = {} as ChatSection;

  @Output() onSelectOwnerGroup = new EventEmitter<ChatUserTreeWithNode>();
  @Output() onUserDbClick = new EventEmitter<ChatUserTreeWithNode>();

  @Output() onToggle: EventEmitter<void> = new EventEmitter();

  @Output() onCheckboxChanges: EventEmitter<ChatUserTreeWithNode> = new EventEmitter<ChatUserTreeWithNode>();

  constructor(
    private popoverService: PopoverService,
    private modalService: NgbModal,
    private destroy: DestroyService,
    private chatService: ChatService,
    private initialsPipe: InitialsPipe
  ) {}

  ngOnInit() {
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
  }

  get isItemChildrenInArchive() {
    return this.node?.data?.children.every((item: ChatUserTreeWithNode) => item.status === StatusesEnum.IN_ARCHIVE);
  }

  get statusIconClass(): string {
    if (this.item.status === StatusesEnum.ACTIVE) {
      return 'status-green';
    } else if (this.item.status === StatusesEnum.VIEW) {
      return 'status-yellow';
    } else {
      return 'status-red';
    }
  }

  get accessRightIcon(): string {
    if (this.ownerId === +this.item.id) {
      return 'crown';
    } else {
      return 'user';
    }
  }

  toggleNodeClicked(): void {
    this.onToggle.next();
  }

  onCheckboxChange(newValue: boolean): void {
    this.item.selected = newValue;
    const chatContact = { ...this.item };
    chatContact.selected = newValue;
    this.onCheckboxChanges.emit(chatContact);
  }

  getContentOffset(): number {
    if (this.node?.level > 1) {
      return this.node?.level ? (this.node.level - 1) * 16 : 0;
    }
    return 0;
  }

  /**
   * Открытие меню выборов доступов юзера
   */
  openSelect(event: MouseEvent) {
    event.stopPropagation();
    this.isAccessSelectOpen = true;
  }

  closeSelect(event?: MouseEvent) {
    event?.stopPropagation();
    this.isAccessSelectOpen = false;
  }

  /**
   * Выбор опции из меню доступов юзера
   */
  selectMenuItem($event: MouseEvent, access: AccessRight) {
    if (access === AccessRights.OWNER) {
      const modal = this.modalService.open(ConfirmationModalComponent, {
        centered: true,
        animation: true,
        windowClass: 'dc-modal fileman-modal modal-window',
      });
      (<ConfirmationModalComponent>(
        modal.componentInstance
      )).text = `Вы действительно хотите назначить пользователя ${this.initialsPipe.transform(
        this.item
      )} владельцем группы и входящих в нее тем?`;
      (<ConfirmationModalComponent>modal.componentInstance).applyEdit = 'Да, применить';
      (<ConfirmationModalComponent>modal.componentInstance).cancelEdit = 'Нет, отменить';
      (<ConfirmationModalComponent>modal.componentInstance).onClose
        .pipe(takeUntil(this.destroy))
        .subscribe((value: boolean) => {
          if (value) {
            this.onSelectOwnerGroup.emit(this.item);
            this.closeSelect();
          }
        });

      return;
    }

    this.onSelectOwnerGroup.emit(this.item);
    this.closeSelect($event);
  }

  userDbClick() {
    // В правой колонке поле children не нужно, там плоский список,
    // в левой мы не меняем объект - только поле disble и selected
    if (this.item.children) {
      const { children, ...itemWithoutChildren } = this.item;
      this.onUserDbClick.emit(itemWithoutChildren);
    } else {
      this.onUserDbClick.emit(this.item);
    }
  }

  /**
   * Открыть диалоговое окно с информцией о пользователе
   * @param file
   */
  showUserInfo(): void {
    const modal = this.modalService.open(FileManagerModalUserInfoComponent, {
      //centered: true,
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });

    modal.componentInstance.user = this.item;
    modal.componentInstance.title = 'Контакты';
  }

  showCompetence(user: User): void {
    const modal = this.modalService.open(UserCompetenceMapModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'md',
    });

    (<UserCompetenceMapModalComponent>modal.componentInstance).user = user;
  }
}
