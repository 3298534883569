import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BACKEND_DATE_FORMAT } from '@app/shared/constants/date.constants';

export interface DoubleDatePickerParams {
  fromDate: string | Date;
  toDate: string | Date;
  fromTime?: string | Date;
  toTime?: string | Date;
  name?: string;
}

@Component({
  selector: 'app-double-datepicker-filter',
  templateUrl: './double-datepicker-filter.component.html',
  styleUrls: ['./double-datepicker-filter.component.scss'],
})
export class DoubleDatepickerFilterComponent {
  private _value: DoubleDatePickerParams;

  @Input() placeholder: string;
  @Input() fromDatePlaceholder: string = '';
  @Input() toDatePlaceholder: string = '';
  @Input() withName: boolean;
  @Input() filterSuggestions: string[];
  @Input() externalDateFormat = BACKEND_DATE_FORMAT;

  form: FormGroup = new FormGroup({
    fromDate: new FormControl(''),
    toDate: new FormControl(''),
    name: new FormControl(''),
  });

  localValue: DoubleDatePickerParams = {
    fromDate: null,
    toDate: null,
    name: null,
  };

  @Output() submitEvent = new EventEmitter<DoubleDatePickerParams>();
  @Output() closeEvent = new EventEmitter<unknown>();
  @Output() searchEvent = new EventEmitter<string>();

  @Input() set value(data: DoubleDatePickerParams) {
    if (!data) return;
    const [fromDate, fromTime] = data[0] ? data[0].split(' ') : '';
    const [toDate, toTime] = data[1] ? data[1]?.split(' ') : '';

    this._value = {
      fromDate: fromDate,
      toDate: toDate,
    };

    this.form.patchValue(this._value);
  }

  get value() {
    return this._value;
  }

  apply(): void {
    const { value } = this.form;
    this.submitEvent.emit(value);
  }

  search(value: string): void {
    this.localValue.name = value;

    this.searchEvent.emit(value);
  }

  select(value: string): void {
    this.localValue.name = value;
  }

  cancel(): void {
    this.closeEvent.emit();
  }
}
