import { AuthService } from '@app/shared/services/auth.service';
import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CHAT_SECTIONS, ChatSectionsEnum } from '../constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';
import { Router } from '@angular/router';
import {
  ChatLastMessageDateThemeInGroups,
  ChatMessage,
  ChatMessagesRoomStore,
  ChatMessagesStore,
  ChatRoom,
  ChatSection,
  ChatSections,
  ChatUserTree,
} from '@app/chat/models/chat.model';
import { Company } from '@app/shared/models/company.model';
import { UserTypes } from '@app/shared/types/user.types';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { NotificationsService } from 'angular2-notifications';
import { delay, filter } from 'rxjs/operators';
import type { CounterData, RoomsBySections, SocketResponseRoomData } from '@app/chat/models';
import { now } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  userId: number;
  userType: UserTypes;
  private chatSectionSelected: ChatSection = CHAT_SECTIONS.corp;
  chatSections: ChatSections = Object.assign({}, CHAT_SECTIONS);
  chatSectionSelectedChanged: Subject<ChatSection> = new Subject<ChatSection>();

  public contacts: any = {};
  contactsChanged: Subject<any> = new Subject<any>();

  userTree: ChatUserTree | null = null;
  userTreeChanged: Subject<any> = new Subject<any>();
  // для выравнивания карточек в ряд в независимости от уровня вложенности
  scrollPosition: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private userTreeExpanded: Array<number> = [];

  public groups: any = {};
  groupsChanged: Subject<any> = new Subject<any>();
  groupExpanded: Subject<any> = new Subject<any>();
  public themes: any = {};
  themesChanged: Subject<any> = new Subject<any>();
  private contactSelected: ChatRoom = { id: null } as ChatRoom;
  private groupSelected: ChatRoom = { id: null } as ChatRoom;
  private themeSelected: ChatRoom = { id: null } as ChatRoom;

  contactSelectedChanged: BehaviorSubject<ChatRoom> = new BehaviorSubject<ChatRoom>({} as ChatRoom);
  groupSelectedChanged: Subject<ChatRoom> = new Subject<ChatRoom>();
  themeSelectedChanged: Subject<ChatRoom> = new Subject<ChatRoom>();

  private isTabsInit = true;
  private tabs: any[] = [];
  tabsChanged: Subject<any[]> = new Subject<any[]>();

  public messages: ChatMessagesStore = {} as ChatMessagesStore;
  messagesChanged: Subject<ChatMessagesStore> = new Subject<ChatMessagesStore>();
  focusChanged: Subject<boolean> = new Subject<boolean>();

  isEditingGroupOrTheme: boolean = false;
  private editingGroupOrThemeObject = {} as ChatRoom;
  isEditingGroupOrThemeChanged: Subject<boolean> = new Subject<boolean>();

  contactsFilter: string = '';
  contactsFilterChanged: Subject<string> = new Subject<string>();

  isEditingContactsOnlyFilter: boolean = false;
  isEditingContactsOnlyFilterChanged: Subject<boolean> = new Subject<boolean>();
  contactsOnlyFilter: any;
  contactsOnlyFilterChanged: BehaviorSubject<any> = new BehaviorSubject<any>({
    roles: [],
    competencies: [],
  });

  chatLoading = new BehaviorSubject<boolean>(true);
  chatLoaded = new BehaviorSubject<boolean>(false);

  public companies: {
    [key: number]: Company;
  } = {};
  public rooms: RoomsBySections = {};
  public users: {
    [key: number]: User;
  } = {};
  usersChanged: Subject<any> = new Subject<any>();

  widgetMessageCounter: number = 0;
  widgetMessageCounterChanged: Subject<number> = new Subject<number>();
  scrollToTabChanged: Subject<ElementRef> = new Subject<ElementRef>();

  constructor(private authService: AuthService, private router: Router, private notify: NotificationsService) {
    this.userId = +this.authService.user_id;
    this.userType = this.authService.user_type;
  }

  public clearContactSelected(): void {
    this.contactSelected = { id: null } as ChatRoom;
    this.contactSelectedChanged.next(Object.assign({}, this.contactSelected));
  }

  scrollMeToTab(elem: ElementRef) {
    this.scrollToTabChanged.next(elem);
  }

  toggleChatOpen() {
    this.isEditingGroupOrTheme = false;

    const isChatOpen = this.router.url.includes('chat');
    const room_id = this.contactSelected?.room_id;

    const outletConfig = isChatOpen ? { popup: null } : { popup: room_id ? ['chat', room_id] : ['chat'] };

    this.router.navigate([{ outlets: outletConfig }]);

    // todo: удалить предыдущую реализацию, если не будет багов после 1799/1800
    // this.isEditingGroupOrTheme = false;
    // if (!this.router.url.includes('chat')) {
    //   this.router.navigate([{ outlets: { popup: ['chat'] } }]);
    // } else {
    //   this.router.navigate([{ outlets: { popup: null } }]);
    // }
  }

  // filter contacts groups themes
  filterContactsGroupsThemes(searchText) {
    this.contactsFilter = searchText.toLowerCase().trim();
    this.contactsFilterChanged.next(this.contactsFilter);
  }

  // filter contacts groups themes end

  // filter only contacts
  toggleContactsOnlyFilter(value) {
    this.isEditingContactsOnlyFilter = value;
    this.isEditingContactsOnlyFilterChanged.next(this.isEditingContactsOnlyFilter);
  }

  getContactsOnlyFilter() {
    return this.contactsOnlyFilter || {};
  }

  setContactsOnlyFilter(value) {
    this.contactsOnlyFilter = value;
    this.contactsOnlyFilterChanged.next(this.contactsOnlyFilter);
  }

  // filter only contacts end

  // counter
  updateCounters(countersData: CounterData[]) {
    Object.keys(CHAT_SECTIONS).forEach((k) => {
      const chatSectionName = CHAT_SECTIONS[k].name;

      countersData.forEach((counterData) => {
        ['contacts', 'groups', 'themes'].forEach((key) => {
          this[key][chatSectionName] = this[key][chatSectionName] || {};
          const chatRoomById = this[key][chatSectionName][counterData.room_id];
          if (!chatRoomById) return;

          // chatRoomById - прошлый counter, counterData - новый counter (непрочитанных было 3 стало 2:  -3 +2
          if (chatRoomById.counter) {
            this.chatSections[k].counter -= chatRoomById.counter;
            this.widgetMessageCounter -= chatRoomById.counter;
          }
          this.chatSections[k].counter += counterData.count;
          this.widgetMessageCounter += counterData.count;

          chatRoomById.counter = counterData.count;
          chatRoomById.last_read_message_id = counterData.last_read_message_id;
          chatRoomById.first_not_read_message_creation_date_in_chat =
            counterData.first_not_read_message_creation_date_in_chat;
          chatRoomById.last_read_message_date_read = counterData.last_read_message_date_read;

          if (this.isTabsInit) {
            this.updateTabs(chatRoomById);
          } else {
            this.updateTabCounters(chatRoomById);
          }

          this[`${key}Changed`].next(Object.assign({}, this[key][this.chatSectionSelected.name]));
        });
      });
    });
    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
    this.isTabsInit = false;
  }

  updateWidgetAndSectionMessageCounter() {
    this.widgetMessageCounter = 0;

    Object.keys(CHAT_SECTIONS).forEach((sectionKey) => {
      const chatSectionName = CHAT_SECTIONS[sectionKey].name;
      let sectionCounter = 0;

      ['contacts', 'groups', 'themes'].forEach((key) => {
        const sectionRooms = this[key]?.[chatSectionName];
        if (!sectionRooms) return;

        Object.values(sectionRooms).forEach((chatRoom: ChatRoom) => {
          const roomCounter = chatRoom?.counter || 0;
          this.widgetMessageCounter += roomCounter;
          sectionCounter += roomCounter;
        });
      });

      this.chatSections[sectionKey].counter = sectionCounter;
    });

    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
  }

  // counter end

  // tabs
  getTabs() {
    return this.tabs.slice();
  }

  updateTabCounters(newTab: ChatRoom) {
    if (newTab.counter === 0 && this.contactSelected?.room_id !== newTab.room_id) {
      this.deleteTab(newTab);
      return;
    }

    const currentTab = this.tabs.find((tab) => tab.room_id === this.contactSelected?.room_id);
    const tabIndex = this.tabs.findIndex((tab) => tab.room_id === newTab.room_id);

    if (tabIndex === -1) {
      if (!currentTab) {
        this.tabs.push(newTab);
      } else {
        this.tabs = this.tabs.filter((tab) => tab.room_id !== currentTab.room_id);
        this.tabs.push(newTab, currentTab);
      }
    } else {
      this.tabs[tabIndex] = newTab;
    }

    this.tabsChanged.next([...this.tabs]);
  }

  updateTabs(newTab: ChatRoom) {
    if (!this.tabs.some((tab) => tab.room_id === newTab.room_id)) {
      this.tabs.push(newTab);
    }

    const unreadTabs = this.tabs
      .filter((tab) => tab?.counter && tab?.first_not_read_message_creation_date_in_chat)
      .sort(
        (a, b) =>
          new Date(a.first_not_read_message_creation_date_in_chat).getTime() -
          new Date(b.first_not_read_message_creation_date_in_chat).getTime()
      );

    /* todo: как будут доделаны группы, проверить правильность выбора текущей вкладки,{contact/theme/group}Selected
     * на данный момент, не зависимо от выбора {contact/theme/group} это всё - contactSelected.
     * */
    const currentTab = this.tabs.find((tab) => tab.room_id === this.contactSelected?.room_id);

    this.tabs =
      currentTab && !unreadTabs.some((tab) => tab.room_id === currentTab.room_id)
        ? [...unreadTabs, currentTab]
        : [...unreadTabs];

    this.tabsChanged.next(this.tabs);
  }

  updateTabsData() {
    this.tabs = this.tabs
      .filter((tab) => tab?.counter || tab.room_id === this.contactSelected?.room_id)
      .map((currentTab) => {
        for (const key of ['contacts', 'groups', 'themes']) {
          const updatedTab = this[key][currentTab.section.name]?.[currentTab.room_id];
          if (updatedTab) return updatedTab;
        }
        return currentTab;
      });
    this.tabsChanged.next(this.tabs);
  }

  deleteTab(delTab) {
    this.tabs = this.tabs.filter((tab) => tab.room_id !== delTab.room_id);
    this.tabsChanged.next(this.tabs.slice());
  }

  removeTabsBySection(sectionName) {
    this.clearContactSelected();
    this.tabs = this.tabs.filter((tab) => tab.section.name !== sectionName);
    this.tabsChanged.next(this.tabs.slice());
  }

  // tabs end

  // editing theme or group object
  getEditingGroupOrThemeObject(): ChatRoom {
    return Object.assign({}, this.editingGroupOrThemeObject);
  }

  setEditingGroupOrThemeObject(object: ChatRoom) {
    this.editingGroupOrThemeObject = object;
  }

  resetEditingGroupOrThemeObject() {
    this.editingGroupOrThemeObject = {} as ChatRoom;
  }

  // editing theme or group object end

  // chat section
  getChatSectionSelected(): ChatSection {
    return Object.assign({}, this.chatSectionSelected);
  }

  setChatSectionSelected(chatSection: ChatSection) {
    this.contacts[chatSection.name] = this.contacts[chatSection.name] || [];
    this.groups[chatSection.name] = this.groups[chatSection.name] || [];
    this.chatSectionSelected = chatSection;
    this.chatSectionSelectedChanged.next(Object.assign({}, this.chatSectionSelected));
    this.updateWidgetAndSectionMessageCounter();
    this.groupsChanged.next(this.groups[chatSection.name]);
    this.contactsChanged.next(this.contacts[chatSection.name]);
  }

  // chat section end

  getMessagesRoomStore(room_id): ChatMessagesRoomStore {
    return (
      this.messages[room_id] ||
      ({
        list: [],
        count: 0,
      } as ChatMessagesRoomStore)
    );
  }

  updateMessages(message: ChatMessage) {
    this.contacts[this.chatSectionSelected.name] = this.contacts[this.chatSectionSelected.name] || [];

    if (!this.messages[message.room_id]) {
      this.messages[message.room_id] = {
        list: [],
        count: 0,
      } as ChatMessagesRoomStore;
    }

    Object.keys(this.groups).forEach((section) => {
      if (this.groups[section][message.room_id]) {
        this.groups[section][message.room_id].last_message_creation_date_in_chat = message.created_at;
      }
    });

    message.author = this.users[message.author_id];
    if (!message.author) {
      console.warn(`нет пользователя в users с ID: ${message.author_id}`);
    }
    this.messages[message.room_id].list.push(message);

    this.contactSelected.last_read_message_id ||= message.id;

    this.messagesChanged.next(Object.assign({}, this.messages));
  }

  deleteMessage(deleteMessage: ChatMessage) {
    this.messages[deleteMessage.room_id].list = this.messages[deleteMessage.room_id].list.filter(
      (message) => message.id !== deleteMessage.id
    );
    this.messagesChanged.next(Object.assign({}, this.messages));
  }

  setPreviousMessages(messages: ChatMessage[], count: number, room_id: string) {
    this.contacts[this.chatSectionSelected.name] = this.contacts[this.chatSectionSelected.name] || [];

    if (!this.messages[room_id]) {
      this.messages[room_id] = {
        list: [],
      } as ChatMessagesRoomStore;
    }

    this.messages[room_id].count = count;
    const existingIds = new Set(this.messages[room_id].list.map((msg) => msg.id));

    const newMessages = messages.filter((message) => {
      if (existingIds.has(message.id)) return false;

      message.author = this.users[message.author_id];
      if (!message.author) {
        console.warn(`нет пользователя в users с ID: ${message.author_id}`);
      }
      return true;
    });

    this.messages[room_id].list.unshift(...newMessages.reverse());

    this.messagesChanged.next(Object.assign({}, this.messages));
  }

  getMessagesListByRoomId(room_id: string): ChatMessage[] {
    const messages = this.messages[room_id]?.list;
    return messages ? [...messages] : [];
  }

  findChatRoomById(room_id: string): ChatRoom | null {
    for (const sectionKey of Object.keys(CHAT_SECTIONS)) {
      const chatSectionName = CHAT_SECTIONS[sectionKey].name;

      for (const key of ['contacts', 'groups', 'themes']) {
        const chatRoomById = this[key][chatSectionName]?.[room_id];

        if (chatRoomById) {
          return Object.assign({}, chatRoomById);
        }
      }
    }

    return null;
  }

  // messages end

  // users
  storeUsers(users: User[]) {
    this.users = {};
    for (const user of users) {
      this.users[user.id] = user;
    }
    this.updateUsersCompanies();
  }

  // users end

  // contacts
  getContacts(): User[] {
    this.contacts[this.chatSectionSelected.name] = this.contacts[this.chatSectionSelected.name] || {};
    return Object.assign({}, this.contacts[this.chatSectionSelected.name]);
  }

  updateContacts() {
    Object.keys(CHAT_SECTIONS).forEach((section) => {
      const chatSectionName = CHAT_SECTIONS[section].name;

      if (this.rooms[chatSectionName]) {
        Object.keys(this.rooms[chatSectionName]).forEach((userId) => {
          if (this.users[userId]) {
            const room = this.rooms[chatSectionName][userId];
            this.contacts[chatSectionName][room.room_id] = this.users[userId];

            const contact = this.contacts[chatSectionName][room.room_id];
            contact.room_id = room.room_id;
            contact.section = CHAT_SECTIONS[section];
          } else {
            const room = this.rooms[chatSectionName][userId];
            delete this.contacts[chatSectionName][room.room_id];
          }
        });
      }
    });

    this.contactsChanged.next(Object.assign({}, this.contacts[this.chatSectionSelected.name]));

    const chatRoom = this.getActiveRoomByUrl(this.contacts);
    // костыль
    setTimeout(() => {
      if (chatRoom) {
        this.setRoomSelected(chatRoom);
      }
    }, 0);
  }

  getContactSelected(): ChatRoom {
    return Object.assign({}, this.contactSelected);
  }

  findGroupById(groupId: number, section: ChatSection) {
    const roomID = Object.keys(this.groups[section.name]).find((rId) => this.groups[section.name][rId].id === groupId);

    return this.groups[section.name][roomID];
  }

  setRoomSelected(contactSelected: ChatRoom) {
    this.contactSelected = contactSelected;
    this.contactSelectedChanged.next(Object.assign({}, this.contactSelected));

    // тригерит смену секций у контактов (в поздних этапах удалить если не пригодиться)
    // if (this.chatSectionSelected.name !== contactSelected.section.name) {
    //   this.setChatSectionSelected(contactSelected.section);
    // }

    if (this.chatSectionSelected.name === ChatSectionsEnum.TRADE) {
    }

    // разворачивает активную группу но сворачивает все остальные (в поздних этапах удалить если не пригодиться)
    // if (contactSelected.group_id) {
    //   const group = this.findGroupById(contactSelected.group_id, contactSelected.section);

    //   this.groupExpanded.next({
    //     ...group,
    //     isGroupExpanded: true,
    //   });
    // }

    if (this.isTradeGroup(contactSelected)) {
      this.groupSelected = contactSelected;
      this.groupSelectedChanged.next(Object.assign({}, this.groupSelected));
    }

    if (this.isTradeTheme(contactSelected)) {
      this.groupSelected = null;
      this.groupSelectedChanged.next({} as ChatRoom);

      this.themeSelected = contactSelected;
      this.themeSelectedChanged.next(Object.assign({}, this.themeSelected));
    }

    this.updateTabs(contactSelected);
  }

  isTradeGroup(room: ChatRoom) {
    return room && room.section && !room.group_id && !room.second_name && room.section.name === ChatSectionsEnum.TRADE;
  }

  isTradeTheme(room: ChatRoom) {
    return room && room.section && room.group_id && !room.second_name && room.section.name === ChatSectionsEnum.TRADE;
  }

  updateAdminManager(users) {
    for (const userId of users) {
      this.users[userId] = this.users[userId] || ({} as User);
      this.users[userId].isCrownActive = true;
    }
    this.updateUsersCompanies();
  }

  hideAllGroup() {
    this.groupExpanded.next({
      isGroupExpanded: true,
    });
  }

  deleteAdminManager(users) {
    for (const userId of users) {
      this.users[userId] = this.users[userId] || ({} as User);
      this.users[userId].isCrownActive = false;
    }
    this.updateUsersCompanies();
  }

  isAdminManager(userId) {
    this.users[userId] = this.users[userId] || ({} as User);
    return this.users[userId].isCrownActive;
  }

  // contacts end

  // groups
  toggleIsEditingGroupOrTheme() {
    this.isEditingGroupOrTheme = !this.isEditingGroupOrTheme;
    this.isEditingGroupOrThemeChanged.next(this.isEditingGroupOrTheme);
  }

  getGroups(): any {
    this.groups[this.chatSectionSelected.name] = this.groups[this.chatSectionSelected.name] || {};
    return this.groups[this.chatSectionSelected.name];
  }

  // isForDutyTso(group: ChatRoom, sectionName: ChatSectionsEnum) {
  //   return (
  //     (!group.tso_id || +group.tso_id !== +this.userId) &&
  //     this.userType === RolesEnum.PARTNER &&
  //     sectionName === ChatSectionsEnum.TECH
  //   );
  // }

  updateGroups(groups: ChatRoom[], section: ChatSection) {
    if (section.name === ChatSectionsEnum.DUTY_TSO) {
      return;
    }
    this.groups[section.name] = this.groups[section.name] || {};
    this.groups[ChatSectionsEnum.DUTY_TSO] = this.groups[ChatSectionsEnum.DUTY_TSO] || {};

    // console.log(groups);

    for (const group of groups) {
      // Для тех. раздела

      // if (!group.owner_id && section.name === ChatSectionsEnum.TECH) {
      //   group.owner_id = group.id;
      // }

      // if (!group.users.includes(group.owner_id) && group.owner_id) {
      //   group.users.push(group.owner_id);
      // }

      if (!group.users.filter((userId) => userId === this.userId).length) {
        this.removeGroup([group], section);
      } else {
        group.section = section;

        // if (this.isForDutyTso(group, section.name)) {
        //   group.section = CHAT_SECTIONS.otp;
        // }

        //ToDo Для тех чатов что то другое нужно
        group.userItems = group.users.map((userId) => this.users[userId]).filter((user) => !!user);
        //!this.isForDutyTso(group, section.name)

        this.groups[section.name][group.room_id] = Object.assign({}, this.groups[section.name][group.room_id], group);
        //else {
        //   this.groups[ChatSectionsEnum.DUTY_TSO][group.room_id] = Object.assign(
        //     {},
        //     this.groups[ChatSectionsEnum.DUTY_TSO][group.room_id],
        //     group
        //   );
        // }
      }
    }

    // console.log('groups', this.groups);

    this.updateTabsData();
    if (section.name === this.chatSectionSelected.name) {
      this.groupsChanged.next(this.groups[this.chatSectionSelected.name]);
    }

    const room = this.getActiveRoomByUrl(this.groups);
    if (room) {
      this.setRoomSelected(room);
    }
  }

  getActiveRoomByUrl(entities): ChatRoom {
    const urlSegments = this.router.url.split('/');
    const roomIdFromUrl = urlSegments[urlSegments.length - 1].replace(')', '');

    const findedSectionName = Object.keys(entities).find((sectionName) => entities[sectionName][roomIdFromUrl]);
    if (!entities[findedSectionName]) {
      return null;
    }
    return entities[findedSectionName][roomIdFromUrl] || null;
  }

  removeGroup(group, section) {
    if (section.name === ChatSectionsEnum.DUTY_TSO) {
      return;
    }
    let sectionName = section.name;

    this.groups[section.name] = this.groups[section.name] || {};
    this.groups[ChatSectionsEnum.DUTY_TSO] = this.groups[ChatSectionsEnum.DUTY_TSO] || {};

    // if (this.isForDutyTso(group, section.name)) {
    //   sectionName = ChatSectionsEnum.DUTY_TSO;
    // }

    delete this.groups[sectionName][group.room_id];

    // console.log(this.groups);

    this.deleteTab(group);
    delete this.messages[group.room_id];

    if (this.contactSelected.room_id === group.room_id) {
      this.clearContactSelected();
      this.goToRoot();
    }

    this.updateTabsData();
    this.updateWidgetAndSectionMessageCounter();
    if (section.name === this.chatSectionSelected.name) {
      this.groupsChanged.next(this.groups[this.chatSectionSelected.name]);
    }
  }

  toggleGroupOpen(group, sectionName) {
    this.groups[sectionName][group.room_id].isExpanded = !group.isExpanded;
  }

  // groups end

  // themes
  getThemes() {
    this.themes[this.chatSectionSelected.name] = this.themes[this.chatSectionSelected.name] || {};
    return Object.assign({}, this.themes[this.chatSectionSelected.name]);
  }

  updateThemes(themes, section) {
    this.themes[section.name] = this.themes[section.name] || {};

    for (const theme of themes) {
      if (!theme.users.includes(theme.owner_id)) {
        theme.users.push(theme.owner_id);
      }
      if (!theme.users.filter((userId) => userId === this.userId).length) {
        this.removeTheme([theme], section);
      } else {
        theme.section = section;
        theme.userItems = theme.users.map((userId) => {
          return this.users[userId];
        });
        this.themes[section.name][theme.room_id] = Object.assign({}, this.themes[section.name][theme.room_id], theme);
      }
    }

    this.updateTabsData();
    if (section.name === this.chatSectionSelected.name) {
      this.themesChanged.next(Object.assign({}, this.themes[this.chatSectionSelected.name]));
    }

    const room = this.getActiveRoomByUrl(this.themes);
    if (room) {
      this.setRoomSelected(room);
    }
  }

  removeTheme(theme, section) {
    this.themes[section.name] = this.themes[section.name] || {};

    delete this.themes[section.name][theme.room_id];
    this.deleteTab(theme);
    delete this.messages[theme.room_id];

    if (this.contactSelected.room_id === theme.room_id) {
      this.clearContactSelected();
      this.goToRoot();
    }

    this.updateTabsData();
    this.updateWidgetAndSectionMessageCounter();
    if (section.name === this.chatSectionSelected.name) {
      this.themesChanged.next(Object.assign({}, this.themes[this.chatSectionSelected.name]));
    }
  }

  toggleThemeOpen(theme, sectionName) {
    this.themes[sectionName][theme.room_id].isExpanded = !theme.isExpanded;
  }

  // themes end

  // rooms
  storeRooms(roomsData: SocketResponseRoomData[], section: ChatSection) {
    this.rooms[section.name] = null;
    this.contacts[section.name] = null;
    this.rooms[section.name] = this.rooms[section.name] || {};

    for (const room of roomsData) {
      this.rooms[section.name][room.user_id] = { room_id: room.room_id };
    }

    this.setRooms();
  }

  setRooms() {
    Object.keys(CHAT_SECTIONS).forEach((section) => {
      const chatSectionName = CHAT_SECTIONS[section].name;
      this.contacts[chatSectionName] = this.contacts[chatSectionName] || {};

      if (this.rooms[chatSectionName]) {
        Object.keys(this.rooms[chatSectionName]).forEach((userId) => {
          const room = this.rooms[chatSectionName][userId];
          this.contacts[chatSectionName][room.room_id] = this.contacts[chatSectionName][room.room_id] || {};

          const contact = this.contacts[chatSectionName][room.room_id];
          contact.room_id = room.room_id;
          contact.id = userId;
        });
      }
    });
    this.updateContacts();
  }

  // rooms end

  // companies
  storeCompanies(companies: Company[]) {
    for (const company of companies) {
      this.companies[company.id] = company;
    }
    this.updateUsersCompanies();
  }

  updateUsersCompanies() {
    for (const id in this.users) {
      if (this.users[id].company_id) {
        this.users[id].company = this.companies[this.users[id].company_id] || this.users[id].company;
      }
    }
    this.updateContacts();
    Object.keys(CHAT_SECTIONS).forEach((section) => {
      const chatSectionName = CHAT_SECTIONS[section].name;
      this.groups[chatSectionName] = this.groups[chatSectionName] || {};
      this.themes[chatSectionName] = this.themes[chatSectionName] || {};
      Object.keys(this.themes[chatSectionName]).forEach((roomId) => {
        if (this.themes[chatSectionName][roomId]) {
          this.themes[chatSectionName][roomId].userItems = this.themes[chatSectionName][roomId].userItems || [];
          this.themes[chatSectionName][roomId].userItems = this.themes[chatSectionName][roomId].userItems.map(
            (user) => this.users[user?.id]
          );
        }
      });
      Object.keys(this.groups[chatSectionName]).forEach((roomId) => {
        if (this.groups[chatSectionName][roomId]) {
          this.groups[chatSectionName][roomId].userItems = this.groups[chatSectionName][roomId].userItems || [];
          this.groups[chatSectionName][roomId].userItems = this.groups[chatSectionName][roomId].userItems.map(
            (user) => this.users[user?.id]
          );
        }
      });
    });
    this.usersChanged.next(this.users);
  }

  goToRoot() {
    this.router.navigate([{ outlets: { popup: 'chat' } }]);
  }

  tryOpenChatByUserId(userId: number) {
    this.goToRoot();

    if (this.chatLoaded.getValue()) {
      const roomId = this.openRoom(userId);

      if (!roomId) {
        this.notify.warn('Чат', 'Не удалось определить раздел чата, пожалуйста обратитесь к администратору');
      }
      return;
    }

    // разобраться с потоками при редизайне чата
    this.chatLoaded
      .pipe(
        delay(100),
        filter((value) => value)
      )
      .subscribe(() => {
        const roomId = this.openRoom(userId);

        if (!roomId) {
          this.notify.warn('Чат', 'Не удалось определить раздел чата, пожалуйста обратитесь к администратору');
        }
      });
  }

  openRoom(userId: number) {
    const roomId = this.getRoomIdByUserId(userId);
    if (!!roomId) {
      this.router.navigate([{ outlets: { popup: ['chat', roomId] } }]).then(() => {
        const room = this.getActiveRoomByUrl(this.contacts) || this.getActiveRoomByUrl(this.groups);

        this.setRoomSelected(room);
      });
    }

    return roomId;
  }

  // надо бы сделать приватным
  getRoomIdByUserId(userId: number): string {
    let roomId: string;
    Object.keys(this.contacts).forEach((sectionKey) => {
      Object.keys(this.contacts[sectionKey]).forEach((roomIdKey) => {
        if (this.contacts[sectionKey][roomIdKey] && +this.contacts[sectionKey][roomIdKey].id === +userId) {
          roomId = roomIdKey;
        }
      });
    });

    return roomId;
  }

  getRoomIdByTradeId(tradeId): string {
    let roomId: string;

    if (!this.groups.trade) {
      return roomId;
    }

    Object.keys(this.groups.trade).forEach((roomIdKey) => {
      if (this.groups.trade[roomIdKey] && +this.groups.trade[roomIdKey].trade_id === +tradeId) {
        roomId = roomIdKey;
      }
    });

    return roomId;
  }

  // companies end

  totalUnreadCount(data: ChatRoom[]) {
    const count = data.reduce((acc, item) => acc + (item?.counter || 0), 0);
    return count <= 10 ? count : '10+';
  }

  // user tree

  storeUserTree(userTree: ChatUserTree) {
    this.userTree = userTree;
    this.userTreeChanged.next();
  }

  getUserTree() {
    return this.userTree;
  }
  public resetChatState() {
    // Очищаем данные (state)
    this.contacts = {};
    this.groups = {};
    this.themes = {};
    this.messages = {};
    this.companies = {};
    this.rooms = {};
    this.users = {};
    this.userTree = null;
    this.chatSectionSelected = Object.assign({}, CHAT_SECTIONS.corp);
    this.chatSections = Object.assign({}, CHAT_SECTIONS);

    // Сбрасываем выбранные элементы
    this.contactSelected = { id: null } as ChatRoom;
    this.groupSelected = { id: null } as ChatRoom;
    this.themeSelected = { id: null } as ChatRoom;
    this.editingGroupOrThemeObject = {} as ChatRoom;
    this.tabs = [];

    // Сбрасываем фильтры
    this.contactsFilter = '';
    this.setContactsOnlyFilter({});
    this.isEditingContactsOnlyFilter = false;
    this.isEditingGroupOrTheme = false;
    this.isTabsInit = true;

    // Сбрасываем счетчики
    this.widgetMessageCounter = 0;

    // Уведомляем подписчиков
    this.contactsChanged.next(this.contacts);
    this.groupsChanged.next(this.groups);
    this.themesChanged.next(this.themes);
    this.messagesChanged.next(this.messages);
    this.usersChanged.next(this.users);
    this.widgetMessageCounterChanged.next(this.widgetMessageCounter);
    this.contactsOnlyFilterChanged.next(this.contactsOnlyFilter);
    this.contactSelectedChanged.next(this.contactSelected);
    this.groupSelectedChanged.next(this.groupSelected);
    this.themeSelectedChanged.next(this.themeSelected);
    this.isEditingContactsOnlyFilterChanged.next(this.isEditingContactsOnlyFilter);
    this.isEditingGroupOrThemeChanged.next(this.isEditingGroupOrTheme);
    this.chatSectionSelectedChanged.next(this.chatSectionSelected);
  }
  isUserExpanded(id: number) {
    return this.userTreeExpanded.includes(id);
  }

  addUserExpanded(id: number) {
    this.userTreeExpanded.push(id);
  }
  removeUserExpanded(id: number) {
    this.userTreeExpanded = this.userTreeExpanded.filter((item) => item !== id);
  }

  sortRoomsByMessageActivity(data: ChatRoom[], themesDate?: ChatLastMessageDateThemeInGroups[]) {
    const unread: ChatRoom[] = [];
    const read: ChatRoom[] = [];
    data.map((item) => {
      const themeNotRead = themesDate?.find(
        (theme) => theme.id === item.id
      )?.first_not_read_theme_message_creation_date_in_chat;
      if (item.first_not_read_message_creation_date_in_chat || themeNotRead) {
        unread.push(item);
      } else {
        read.push(item);
      }
    });

    unread.sort((a, b) => {
      const firstNotReadTheme =
        themesDate?.find((item) => +item.id === +a.id)?.first_not_read_theme_message_creation_date_in_chat || null;
      const secondNotReadTheme =
        themesDate?.find((item) => +item.id === +b.id)?.first_not_read_theme_message_creation_date_in_chat || null;

      const firstNotRead = a.first_not_read_message_creation_date_in_chat;
      const secondNotRead = b.first_not_read_message_creation_date_in_chat;

      const firstDate =
        firstNotReadTheme && new Date(firstNotReadTheme) < new Date(firstNotRead)
          ? new Date(firstNotReadTheme).getTime()
          : new Date(firstNotRead).getTime();

      const secondDate =
        secondNotReadTheme && new Date(secondNotReadTheme) < new Date(secondNotRead)
          ? new Date(secondNotReadTheme).getTime()
          : new Date(secondNotRead).getTime();

      return firstDate - secondDate;

      // return (
      //   new Date(firstNotRead || firstNotReadTheme).getTime() - new Date(secondNotRead || secondNotReadTheme).getTime()
      // );
    });

    read.sort((a, b) => {
      const firstTheme = themesDate?.find((item) => +item.id === +b.id);
      const secondTheme = themesDate?.find((item) => +item.id === +a.id);
      const firstLastReadTheme = firstTheme?.theme_last_reed_message_date
        ? firstTheme?.theme_last_reed_message_date
        : firstTheme?.created_at;
      const secondLastReadTheme = secondTheme?.theme_last_reed_message_date
        ? secondTheme?.theme_last_reed_message_date
        : secondTheme?.created_at;

      const firstLastCreation = b.last_read_message_date_read ? b.last_read_message_date_read : b.created_at;
      const secondLastCreation = a.last_read_message_date_read ? a.last_read_message_date_read : a.created_at;

      const firstLastMessageDate =
        new Date(firstLastReadTheme).getTime() > new Date(firstLastCreation).getTime()
          ? new Date(firstLastReadTheme).getTime()
          : new Date(firstLastCreation).getTime();

      const secondLastMessageDate =
        new Date(secondLastReadTheme).getTime() > new Date(secondLastCreation).getTime()
          ? new Date(secondLastReadTheme).getTime()
          : new Date(secondLastCreation).getTime();

      return firstLastMessageDate - secondLastMessageDate;
    });

    return [...unread, ...read];
  }
}
