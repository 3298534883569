import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';

import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { Role } from '@app/shared/models/user.model';
import { ChatSectionsEnum, TradeRoomsTypes } from '@app/chat/constants/chat-sections.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { UserStatusesTypes } from '@app/shared/types/user-statuses.types';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';

@Component({
  selector: 'app-chat-theme',
  templateUrl: './chat-theme.component.html',
  styleUrls: ['./chat-theme.component.scss'],
})
export class ChatThemeComponent implements OnInit, OnDestroy {
  @Input() userId: number | string;
  @Input() group: ChatRoom;
  @Input() theme: ChatRoom;
  @Input() contactSelected: ChatRoom;
  @Input() userType: RolesEnum;
  @Input() isNested = false;

  userStatus: UserStatusesTypes;
  themes: ChatRoom[] = [];
  roles: Role[] = ROLES;
  chatSectionSelected: ChatSection;
  isReallyRemoving = false;
  chatSectionsEnum = ChatSectionsEnum;
  statusesEnum = StatusesEnum;
  tradeRoomsTypes = TradeRoomsTypes;
  themeSelected: ChatRoom;
  scrollPosition: number = 0;
  filterString: string;
  isExpanded = false;

  isShowRenameModal = false;

  isShowThemeModal = false;

  isProvider = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  protected totalUnreadMessagesCounter: number | '10+';

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private socketDataService: SocketDataService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.filterString = this.chatService.contactsFilter;

    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
      this.themeSelected = themeSelected;
    });

    this.chatService.scrollPosition.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.scrollPosition = res;
    });
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
        this.filterString = filterString;
        this.filterThemes(this.chatService.getThemes());
      });
      this.userId = +this.authService.user_id;
      this.filterThemes(this.chatService.getThemes());

      this.chatService.themeSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themeSelected) => {
        this.themeSelected = themeSelected;
      });
    }
    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterThemes(this.chatService.getThemes());
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([this.theme]);
    });

    this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([this.theme]);

    if (this.theme) {
      this.isProvider = !!this.theme?.providers?.find((provider) => provider === +this.authService.user_id);
    }
    this.authService.userStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe((userStatus) => {
      this.userStatus = userStatus;
    });
  }

  onThemeSelect(theme: ChatRoom) {
    // console.log('SELECTED THEME', theme);
    this.chatService.setRoomSelected(theme);
    this.chatService.focusChanged.next(true);
  }

  onThemeUsersToggle() {
    this.chatService.toggleThemeOpen(this.theme, this.chatService.getChatSectionSelected().name);
  }

  // Актуализировать или удалить
  onThemeEdit() {
    this.chatService.setEditingGroupOrThemeObject({
      ...this.theme,
      group: this.group,
    });

    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  openRenameThemeModal() {
    this.isShowRenameModal = true;
  }

  openCreateModal() {
    this.isShowThemeModal = true;
  }

  closeModalName() {
    this.isShowRenameModal = false;
  }
  closeCreateTheme() {
    this.isShowThemeModal = false;
  }

  renameTheme(title: string) {
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.updateTopic({
          section: this.chatSectionSelected.name,
          id: this.theme.id,
          title,
        });
        break;

      case ChatSectionsEnum.TRADE:
        this.chatDataService.updateTradeTopic({
          title,
          id: this.theme.id,
        });
        break;

      case ChatSectionsEnum.TECH:
        this.chatDataService.updateTechTopic({
          id: this.theme.id,
          title,
        });
        break;
    }

    this.isShowRenameModal = false;
  }

  onRemoveThemeAsk() {
    this.isReallyRemoving = true;
  }

  onRemoveTheme() {
    if (this.chatSectionSelected.name === this.chatSectionsEnum.TRADE) {
      this.chatDataService.removeTradeTopic(this.theme.id);
    } else {
      this.chatDataService.removeTopic({
        topic_id: this.theme.id,
        section: this.chatService.getChatSectionSelected(),
        room_id: this.theme.room_id,
      });
    }

    this.isReallyRemoving = false;
  }

  // Актализировать или удалить метод
  canEditTheme() {
    return (
      (this.userId === this.group.owner_id &&
        ((this.chatSectionSelected.name === this.chatSectionsEnum.TRADE &&
          (this.theme.type === this.tradeRoomsTypes.CUSTOMER_OTHER ||
            this.theme.type === this.tradeRoomsTypes.PROVIDER_OTHER)) ||
          this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN ||
          this.chatSectionSelected.name === this.chatSectionsEnum.HOLDING)) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.owner_id)
    );
  }

  get isOwner() {
    return !(this.userId === this.group.owner_id || this.userType === RolesEnum.SUPERUSER);
  }

  createTradeTopic(event: string) {
    this.socketDataService.addTradeTopic(
      { title: event, trade_id: this.theme.trade_id, owner_id: 11, users: [] },
      this.theme.id
    );
    this.isShowThemeModal = false;
  }

  filterThemes(themes: ChatRoom) {
    this.themes = [];

    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (theme.group_id === this.theme.id) {
        this.themes.push(theme);
      }
    });
  }
  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  get isTradeChatFirstLevel() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && !this.isNested;
  }
  get isTradeChatEndLevel() {
    return this.chatSectionSelected.name === this.chatSectionsEnum.TRADE && this.isNested;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
