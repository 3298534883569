import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  AggregationDataGeneral,
  DataForUpdateRestrictionsTrade,
  PaymentRegistryReportOptions,
} from '../models/payment-registry.model';
import { GeneralWalletAggregation } from '../models/wallet-balance.model';
import {
  AddAccrualModel,
  FileLogAccrualModel,
  FileLogFilterAccrualModel,
  GeneralAccrualAggregation,
  ReverseAccrualModel,
} from '../models/payment-accrual.model';
import { ParamsApiUser, User } from '@app/shared/models/user.model';
import { filterToParams, mapToObject } from '@app/shared/utils';
import { FilterPayment } from '@app/+tariffs/models/payment-state.model';

@Injectable()
export class PaymentRegistryService {
  constructor(private http: HttpClient) {}

  getPaymentRegistry(filter: FilterPayment[], extra: { [key: string]: any } = {}): Observable<AggregationDataGeneral> {
    const filteredObject = mapToObject(extra || {});
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        ...filteredObject,
      },
    });

    return this.http.get<AggregationDataGeneral>(`${environment.api_url}/register/payment-register`, {
      headers: {
        Accept: 'application/vnd.api+json',
      },
      params,
    });
  }

  getCustomerWallet(filter: FilterPayment[]): Observable<AggregationDataGeneral> {
    const params: { [param: string]: string | string[] } = filterToParams<FilterPayment[]>(filter);
    return this.http.get<AggregationDataGeneral>(`${environment.api_url}/register/customer`, { params });
  }

  getUsers(filter: ParamsApiUser): Observable<User[]> {
    const params: { [param: string]: string | string[] } = filterToParams<ParamsApiUser>(filter);
    return this.http.get<User[]>(`${environment.api_url}/users`, { params });
  }

  getAgentWallet(filter: FilterPayment[]): Observable<AggregationDataGeneral> {
    const params: { [param: string]: string | string[] } = filterToParams<FilterPayment[]>(filter);
    return this.http.get<AggregationDataGeneral>(`${environment.api_url}/register/agent`, { params });
  }

  updateRestrictionsTrade(body: DataForUpdateRestrictionsTrade) {
    return this.http.post<AggregationDataGeneral>(`${environment.api_url}/update-restrictions-trade`, body);
  }

  getFileByPayerId(payerId: number, month: number, year: number, reportType: 'aps' | 'invoice'): Observable<any> {
    return this.http.get(`${environment.api_url}/month-bill/${reportType}/${year}/${month}/${payerId}`, {
      responseType: 'blob',
    });
  }

  pdfReport({ registryType, name, pageSize, layout }: PaymentRegistryReportOptions) {
    return this.http.post<any>(`${environment.api_url}/registry-trades-to-pdf/${registryType}`, {
      html_report: name,
      orientation: layout,
      page_size: pageSize,
    });
  }

  downloadPdf(url) {
    return this.http.get(url, { responseType: 'blob', observe: 'response' });
  }

  /**
   * Выгрузка кошелька агента
   * @param filter
   */
  getWalletAgent(filter: Partial<any>): Observable<GeneralWalletAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralWalletAggregation>(`${environment.api_url}/wallet/agent`, { params });
  }

  /**
   * Выгрузка кошелька клиента
   * @param filter
   */
  getWalletCustomer(filter: Partial<any>): Observable<GeneralWalletAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralWalletAggregation>(`${environment.api_url}/wallet/customer`, { params });
  }

  /**
   * Выгрузка кошелька суперюзера и бухгалтера
   * @param filter
   */
  getWalletFull(filter: Partial<any>): Observable<GeneralWalletAggregation> {
    const params = filterToParams<any>(filter);
    return this.http.get<GeneralWalletAggregation>(`${environment.api_url}/wallet/full`, { params });
  }

  getAccrual(filter: FilterPayment[], extra: { [key: string]: any } = {}): Observable<GeneralAccrualAggregation> {
    const filteredObject = mapToObject(extra || {});
    const params = new HttpParams({
      fromObject: {
        filter: JSON.stringify(filter),
        ...filteredObject,
        sort_id: 'desk',
      },
    });

    return this.http.get<GeneralAccrualAggregation>(`${environment.api_url}/transaction`, {
      headers: {
        Accept: 'application/vnd.api+json',
      },
      params,
    });
  }

  addAccrual(body: AddAccrualModel) {
    return this.http.post<any>(`${environment.api_url}/transaction`, body);
  }

  reverseAccrual(body: ReverseAccrualModel) {
    return this.http.post<any>(`${environment.api_url}/transaction/reverse`, body);
  }

  getFileLogAccrual(filter: Partial<FileLogFilterAccrualModel>) {
    const params: { [param: string]: string | string[] } = filterToParams<FileLogFilterAccrualModel>(filter);
    return this.http.get<FileLogAccrualModel[]>(`${environment.api_url}/transaction/from-file/history`, { params });
  }
}
