import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// TODO: обновить socket.io-client до v4
const { io } = require('socket.io-client');

import { environment } from '@app/environments/environment';
import { CHAT_SECTIONS, ChatSectionsEnum } from '../chat/constants/chat-sections.constants';

import { AuthService } from '../shared/services/auth.service';
import { ChatService } from '../chat/services/chat.service';
import { RolesEnum } from '../shared/constants/roles.constants';
import { User } from '../shared/models/user.model';
import { UserTypes } from '../shared/types/user.types';

import {
  ChatGroup,
  ChatGroupUpdate,
  ChatMessage,
  ChatThemeTradeUpdate,
  GroupRemove,
  Theme,
  ThemeRemove,
  ThemeTechUpdate,
  ThemeUpdate,
  TradeTheme,
  ChatUserTree,
  TechGroup,
  ChatRoom,
} from '../chat/models/chat.model';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '@app/notification/services/notification.service';
import {
  NOTIFICATION_CREATE_ZIP,
  NOTIFICATION_NOT_ENOUGH_FREE_SPACE,
  NOTIFICATION_NOT_FREE_SPACE,
} from '@app/shared/constants/file-manager.constants';
import { UserFile } from '@app/file-manager/models/user-file.model';
import { TradeRating, TradeTabsLocked } from '@app/+trades/models/trades.model';
import type { CounterData, SocketResponseRoomData } from '@app/chat/models';
import { DEFAULT_MESSAGE_LOAD_COUNT } from '@app/shared/constants/chat.constants';
import { ChatData } from '@app/shared/models/chat-data.models';
import { Company } from '@app/shared/models/company.model';
import type { NotificationCounter } from '@app/shared/models/chat-data.models';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})
export class SocketDataService implements OnDestroy {
  private url = environment.api_url;
  private host = new URL(this.url).host;
  private destroy$ = new Subject();

  private options: SocketIOClient.ConnectOpts = {
    path: '/api/v1/im',
    transports: ['websocket', 'polling'],
    query: {
      auth_token: null,
    },
  };
  private socket: SocketIOClient.Socket;
  private user: User;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private notify: NotificationsService
  ) {
    this.authService.userStream.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.chatService.userId = +this.authService.user_id;
      this.chatService.userType = this.authService.user_type;
      this.options.query['auth_token'] = this.authService.getToken();

      if (this.socket) return;
      this.init();
    });
  }

  init() {
    this.socket = io(this.host, this.options);

    this.socket.on('connect', () => {
      this.connected();
      this.getAllChatData();
    });

    this.socket.on('update users', (users) => {
      // console.log('USERS', users);
      const { data, version }: { data: User[]; version: number } = users;

      this.chatService.storeUsers(version ? data : users);

      this.chatService.chatLoaded.next(true);
      this.chatService.chatLoading.next(false);
    });

    this.socket.on('update users tree', (usersTree) => {
      // console.log('USERS tree', usersTree);
      const { data, version }: { data: ChatUserTree; version: number } = usersTree;

      this.chatService.storeUserTree(version ? data : usersTree);
    });

    this.socket.on('update companies', (companies) => {
      // console.log('companies', companies);
      const { data, version }: { data: Company[]; version: number } = companies;

      this.chatService.storeCompanies(version ? data : companies);
    });

    for (const key in CHAT_SECTIONS) {
      if (CHAT_SECTIONS.hasOwnProperty(key)) {
        this.socket.on(`update ${CHAT_SECTIONS[key].name} contacts`, (rooms) => {
          if (this.isCanShowContacts(CHAT_SECTIONS[key].name)) {
            // console.log(`update ${CHAT_SECTIONS[key].name} contacts`, rooms);
            const { data, version }: { data: SocketResponseRoomData[]; version: number } = rooms;
            this.chatService.storeRooms(version ? data : rooms, CHAT_SECTIONS[key]);
          }
        });

        this.socket.on(`update ${CHAT_SECTIONS[key].name} groups`, (groups) => {
          // console.log(`update ${CHAT_SECTIONS[key].name} groups`, groups);
          const { data, version }: { data: ChatRoom[]; version: number } = groups;
          this.chatService.updateGroups(version ? data : groups, CHAT_SECTIONS[key]);
        });

        this.socket.on(`delete ${CHAT_SECTIONS[key].name} groups`, (groups) => {
          // console.log(`REMOVED GROUPS ${CHAT_SECTIONS[key].name}`, groups);
          const { data, version }: { data: any; version: number } = groups;
          this.chatService.removeGroup(version ? data : groups, CHAT_SECTIONS[key]);
        });

        this.socket.on(`update ${CHAT_SECTIONS[key].name} topics`, (themes) => {
          // console.log(`update ${CHAT_SECTIONS[key].name} topics`, themes);
          const { data, version }: { data: any; version: number } = themes;
          this.chatService.updateThemes(version ? data : themes, CHAT_SECTIONS[key]);
        });

        this.socket.on(`delete ${CHAT_SECTIONS[key].name} topics`, (themes) => {
          // console.log(`REMOVED THEMES ${CHAT_SECTIONS[key].name}`, themes);
          const { data, version }: { data: any; version: number } = themes;
          this.chatService.removeTheme(version ? data : themes, CHAT_SECTIONS[key]);
        });
      }
    }

    this.socket.on('chat message', (message) => {
      // console.log('chat message', message);
      const { data, version }: { data: ChatMessage; version: number } = message;
      this.getMessage(version ? data : message);
    });

    this.socket.on('delete message', (message) => {
      // console.log('delete message', message);
      const { data, version }: { data: ChatMessage; version: number } = message;
      this.chatService.deleteMessage(version ? data : message);
    });

    this.socket.on('update room counter', (counterData) => {
      // console.log('update room counter', data);
      const { data, version }: { data: CounterData[]; version: number } = counterData;
      this.chatService.updateCounters(version ? data : counterData);
    });

    this.socket.on('update admin managers', (adminManagersData) => {
      // console.log('update admin managers', data);
      const { data, version }: { data: any; version: number } = adminManagersData;
      this.chatService.updateAdminManager(version ? data : adminManagersData);
    });

    this.socket.on('delete admin managers', (adminManagersData) => {
      // console.log('delete admin managers', data);
      const { data, version }: { data: any; version: number } = adminManagersData;
      this.chatService.deleteAdminManager(version ? data : adminManagersData);
    });

    this.socket.on('count new notification', (notificationData) => {
      // console.log('count new notification', data);
      const { data, version }: { data: NotificationCounter; version: number } = notificationData;
      this.notificationService.updateCounters(version ? data : notificationData);
    });

    this.socket.on('notification', (notificationData) => {
      // console.log('notification', data);
      const { data, version }: { data: any; version: number } = notificationData;

      const newData = version ? data : notificationData;
      if (
        newData?.notification?.type === NOTIFICATION_CREATE_ZIP ||
        newData?.notification?.type === NOTIFICATION_NOT_ENOUGH_FREE_SPACE ||
        newData?.notification?.type === NOTIFICATION_NOT_FREE_SPACE
      ) {
        this.notificationService.addFileNotification(newData);
      }
      this.notificationService.addNotification(newData);
    });

    this.socket.on('trade rating online', (tradeRating) => {
      // console.log('trade rating online', data);
      const { data, version }: { data: TradeRating; version: number } = tradeRating;
      this.notificationService.updateCustomerPlayTrades(version ? data : tradeRating);
    });

    this.socket.on('disconnect', () => this.disconnected());
  }

  getAllChatData() {
    this.http.get<ChatData>(`${this.url}/services/get_all_data`).subscribe({
      next: (data) => this.setAllChatData(data),
      error: () => this.notify.error('Чат временно не доступен, попробуйте позже.'),
    });
  }

  private setAllChatData(data: ChatData) {
    // console.log('update ALL chat data', data);
    if (data.update_users) {
      this.chatService.storeUsers(data.update_users);
    }

    if (data.update_companies) {
      this.chatService.storeCompanies(data.update_companies);
    }

    if (data.update_room_counter) {
      this.chatService.updateCounters(data.update_room_counter);
    }

    if (data.count_new_notification) {
      this.notificationService.updateCounters(data.count_new_notification);
    }

    if (data.update_users_tree) {
      this.chatService.storeUserTree(data.update_users_tree);
    }

    if (data.update_duty_tso) {
      // добавить метод обновления update_duty_tso когда он появится.
    }

    for (const key in CHAT_SECTIONS) {
      if (CHAT_SECTIONS.hasOwnProperty(key)) {
        const sectionName = CHAT_SECTIONS[key].name;

        const contactsKey = `update_${sectionName}_contacts`;
        if (data[contactsKey] && this.isCanShowContacts(sectionName)) {
          this.chatService.storeRooms(data[contactsKey], CHAT_SECTIONS[key]);
        }

        const groupsKey = `update_${sectionName}_groups`;
        if (data[groupsKey]) {
          this.chatService.updateGroups(data[groupsKey], CHAT_SECTIONS[key]);
        }

        const topicsKey = `update_${sectionName}_topics`;
        if (data[topicsKey]) {
          this.chatService.updateThemes(data[topicsKey], CHAT_SECTIONS[key]);
        }
      }
    }

    this.chatService.chatLoaded.next(true);
    this.chatService.chatLoading.next(false);
  }

  trackTradeTabsLocking(): Observable<TradeTabsLocked> {
    return new Observable<TradeTabsLocked>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('trade tabs locked', (tradeTabsLocked) => {
        const { data, version }: { data: TradeTabsLocked; version: number } = tradeTabsLocked;
        observer.next(version ? data : tradeTabsLocked);
      });

      return () => {
        this.socket.off('trade tabs locked');
      };
    });
  }

  trackSupplierTradeTabsLocking(): Observable<TradeTabsLocked> {
    return new Observable<TradeTabsLocked>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('provider trade tabs locked', (providerTradeTabsLocked) => {
        const { data, version }: { data: TradeTabsLocked; version: number } = providerTradeTabsLocked;
        observer.next(version ? data : providerTradeTabsLocked);
      });

      return () => {
        this.socket.off('provider trade tabs locked');
      };
    });
  }

  emitEmployeesAccessChanged(): Observable<void> {
    return new Observable<void>((observer) => {
      this.socket.emit('join_rooms_by_user', { auth_token: this.authService.getToken() });

      this.socket.on('system notification', () => {
        observer.next();
      });

      return () => {
        this.socket.off('system notification');
      };
    });
  }

  isCanShowContacts(sectionName: ChatSectionsEnum) {
    return (
      (([RolesEnum.ADMIN_OF_DIRECTION, RolesEnum.ADMIN_OF_USER, RolesEnum.OPERATOR] as UserTypes[]).includes(
        this.user.type
      ) &&
        (sectionName === ChatSectionsEnum.HOLDING || sectionName === ChatSectionsEnum.TRADE)) ||
      (([RolesEnum.SUPERUSER, RolesEnum.ACCOUNTANT, RolesEnum.EXPERT, RolesEnum.PARTNER] as UserTypes[]).includes(
        this.user.type
      ) &&
        sectionName === ChatSectionsEnum.ADMIN) ||
      sectionName === ChatSectionsEnum.TECH
    );
  }

  getUpdateGroupEventName(sectionName: ChatSectionsEnum): string {
    return `update ${sectionName} groups`;
  }

  // themes
  addTopic(topic: Theme) {
    this.http
      .post(`${this.url}/chat/${topic.section}_topics/${topic.group_id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
      })
      .subscribe();
  }

  addTechTopic(topic: TechGroup) {
    this.http
      .post(`${this.url}/chat/tech_topics/${topic.group_id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
        create_from: topic.create_from,
      })
      .subscribe();
  }

  addTradeTopic(topic: TradeTheme, id: number) {
    this.http
      .post(`${this.url}/chat/trade-topics/${id}`, {
        title: topic.title,
        users: topic.users.map((user) => +user),
        trade_id: topic.trade_id,
      })
      .subscribe();
  }

  updateTradeTopic(topic: ChatThemeTradeUpdate) {
    this.http
      .patch(`${this.url}/chat/trade-topics/${topic.id}`, {
        title: topic.title,
        users: topic.users?.map((user) => +user),
      })
      .subscribe();
  }

  removeTradeTopic(theme_id: string | number) {
    this.http.delete(`${this.url}/chat/trade-topics/${theme_id}`).subscribe();
  }

  updateTechTopic(topic: ThemeTechUpdate) {
    this.http
      .patch(`${this.url}/chat/tech_topic/${topic.id}`, {
        title: topic.title,
      })
      .subscribe();
  }

  updateTopic(topic: ThemeUpdate) {
    this.http
      .patch(`${this.url}/chat/${topic.section}_topic/${topic.id}`, {
        title: topic.title,
        users: topic.users,
      })
      .subscribe();
  }

  removeTopic(topic: ThemeRemove) {
    this.http.delete(`${this.url}/chat/${topic.section.name}_topic/${topic.topic_id}`).subscribe(() => {
      delete this.chatService.themes[topic.section.name][topic.room_id];
      this.chatService.updateThemes([], topic.section);
    });
  }

  loadPreviousMessages(data: { room_id: string }): Observable<{ items: ChatMessage[]; count: number }> {
    return this.http.get<{ items: ChatMessage[]; count: number }>(
      `${this.url}/chat/room/${data.room_id}/messages?count=${DEFAULT_MESSAGE_LOAD_COUNT}&offset=0`
    );
  }

  getMessage(message: ChatMessage) {
    this.chatService.updateMessages(message);
  }

  setMessageRead(room_id: string, ids: number[]) {
    this.http.post(`${this.url}/chat/mark_as_read`, { room_id, messages_ids: ids }).subscribe();
  }

  sendMessage(room_id: string, content: string, files: UserFile[]) {
    const params = {
      room_id,
      content,
      attached_files_ids: files.map((f) => +f.id),
    };

    if (!params.content) {
      delete params.content;
    }

    this.http.post(`${this.url}/chat/messages`, params).pipe().subscribe();
  }

  deleteMessage(message_id) {
    this.http.delete(`${this.url}/chat/message/${message_id}`).subscribe();
  }

  addGroup(group: ChatGroup) {
    this.http
      .post(`${this.url}/chat/${group.section}_groups`, {
        title: group.title,
        users: group.users,
      })
      .subscribe();
  }

  addTechGroup(group: TechGroup) {
    this.http
      .post(`${this.url}/chat/tech_groups`, {
        title: group.title,
        users: group.users,
        create_from: group.create_from,
      })
      .subscribe();
  }

  changeAdminGroupOwner(group: ChatGroupUpdate) {
    this.http
      .patch(`${this.url}/chat/admin_group/${group.group_id}`, {
        owner_id: group.owner_id,
      })
      .subscribe(() => {
        if (group.owner_id === this.user.id) {
          delete group.owner_id;
          this.updateGroup(group);
        } else {
          this.updateAdminGroupOwner(group);
        }
      });
  }

  updateAdminGroupOwner(group) {
    const updatedGroups = this.chatService.getGroups().map((item) => {
      if (item.id === group.id) {
        item.owner_id = group.owner_id;
      }
      return item;
    });
    this.chatService.updateGroups(updatedGroups, CHAT_SECTIONS.admin);
  }

  updateGroup(group: ChatGroupUpdate) {
    this.http
      .patch(`${this.url}/chat/${group.section}_group/${group.group_id}`, {
        title: group.title,
        users: group.users,
        owner_id: group.owner_id,
      })
      .subscribe();
  }

  removeGroup(group: GroupRemove) {
    this.http.delete(`${this.url}/chat/${group.section}_group/${group.group_id}`).subscribe();
  }

  // groups end
  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
    this.chatService.resetChatState();
  }

  private connected() {
    console.log('Connected to chat');
  }

  private disconnected() {
    /* Сбрасываем флаги - нужно для повторного получения данных при реконнекте.
     * chatLoading - true > при дисконекте появится лоадер
     * chatLoaded - false > флаг, что бы при восстановлении соединения данные обновились */
    this.chatService.chatLoading.next(true);
    this.chatService.chatLoaded.next(false);
    console.log('Disconnected from chat');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
