import { takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';

import { ChatService } from '@app/chat/services/chat.service';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [DestroyService],
})
export class ChatComponent {
  isEditingGroupOrTheme: boolean;
  isEditingContactsOnlyFilter: boolean;
  isContactsHidden: boolean = false;
  contactSelected: ChatRoom = {} as ChatRoom;
  chatSectionSelected: ChatSection = {} as ChatSection;
  chatSectionsEnum = ChatSectionsEnum;
  groupSelected: ChatRoom;
  isTradeGroup = this.chatService.isTradeGroup;

  chatLoading$ = this.chatService.chatLoading.asObservable();

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private readonly destroy$: DestroyService
  ) {
    this.chatService.isEditingGroupOrThemeChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((isEditingGroupOrTheme) => (this.isEditingGroupOrTheme = isEditingGroupOrTheme));

    this.chatService.isEditingContactsOnlyFilterChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((isEditingContactsOnlyFilter) => (this.isEditingContactsOnlyFilter = isEditingContactsOnlyFilter));

    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;
    });

    this.authService.userStream.pipe().subscribe(() => {
      this.isEditingGroupOrTheme = this.chatService.isEditingGroupOrTheme;
      this.isEditingContactsOnlyFilter = this.chatService.isEditingContactsOnlyFilter;

      this.contactSelected = this.chatService.getContactSelected();
      this.chatSectionSelected = this.chatService.getChatSectionSelected();
    });
  }

  closeEditingGroupOrTheme() {
    this.chatService.resetEditingGroupOrThemeObject();
    this.chatService.isEditingGroupOrThemeChanged.next(false);
    this.chatService.toggleContactsOnlyFilter(false);
  }

  scrollEvent(element) {
    this.chatService.scrollPosition.next(element.scrollLeft);
  }

  onChatClose() {
    this.chatService.toggleChatOpen();
  }

  toggleContactsHidden() {
    this.isContactsHidden = !this.isContactsHidden;
  }
}
