import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { ChatSectionsEnum, TechTypeEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { User } from '@app/shared/models/user.model';

@Component({
  selector: 'app-chat-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class ChatGroupComponent implements OnInit, OnDestroy {
  @Input() group: ChatRoom;
  @Input() contactSelected: ChatRoom;
  @Input() techType: TechTypeEnum | null = null;

  roles = ROLES;
  themes: ChatRoom[] = [];
  allThemes: ChatRoom[] = [];
  userId: number;
  user: User;
  filterString: string;
  chatSectionSelected: ChatSection;
  groupSelected: ChatRoom;
  isReallyRemoving: boolean = false;
  chatSectionsEnum = ChatSectionsEnum;
  isShowRenameModal = false;
  isShowThemeModal = false;
  scrollPosition: number = 0;
  listWidth = 310;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  protected totalUnreadMessagesCounter: number | '10+';

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.filterString = this.chatService.contactsFilter;
    this.chatSectionSelected = this.chatService.getChatSectionSelected();
    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.filterThemes(this.chatService.getThemes());
    });
    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => (this.user = user));
    this.userId = +this.authService.user_id;
    this.filterThemes(this.chatService.getThemes());

    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themes) => {
      this.filterThemes(themes);
    });

    this.totalUnreadCounter();
    this.chatService.groupsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.totalUnreadCounter();
    });

    this.chatService.chatSectionSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chatSectionSelected) => (this.chatSectionSelected = chatSectionSelected));

    this.chatService.groupExpanded.pipe(takeUntil(this.ngUnsubscribe)).subscribe((group) => {
      this.group.isGroupExpanded = this.group.id === group.id;
    });

    this.chatService.groupSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groupSelected) => {
      this.groupSelected = groupSelected;
    });

    this.chatService.scrollPosition.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.scrollPosition = res;
    });
  }

  filterThemes(themes) {
    this.themes = [];

    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (theme.group_id === this.group.id) {
        this.themes.push(theme);
      }
      this.allThemes.push(theme);
    });

    this.themes = this.chatService.sortRoomsByMessageActivity(this.themes);

    // Для тех. чата
    if (this.group.topics) {
      this.group.topics.forEach((t) => {
        t.section = this.chatSectionSelected;
        this.themes.push(t);
      });
    }

    this.totalUnreadCounter();
  }

  onGroupSelect(group: ChatRoom) {
    if (this.chatSectionSelected.name !== this.chatSectionsEnum.TRADE) {
      this.chatService.setRoomSelected(group);
      this.chatService.focusChanged.next(true);
    }
  }

  onAddTheme($event: Event) {
    $event.stopPropagation();
    this.chatService.setEditingGroupOrThemeObject({
      group_id: this.group.id,
      owner_id: this.group.owner_id,
      trade_id: this.group.trade_id,
      group: this.group,
    } as ChatRoom);
    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  onGroupEdit() {
    this.chatService.setEditingGroupOrThemeObject(this.group);
    this.chatService.isEditingGroupOrThemeChanged.next(true);
  }

  onRemoveGroupAsk() {
    this.isReallyRemoving = true;
  }

  onRemoveGroup() {
    this.isReallyRemoving = false;
    this.chatDataService.removeGroup({
      group_id: this.group.id,
      section: this.chatService.getChatSectionSelected().name,
    });
  }

  openRenameGroupModal() {
    this.isShowRenameModal = true;
  }

  closeModalName() {
    this.isShowRenameModal = false;
  }

  openCreateThemeModal() {
    this.isShowThemeModal = true;
  }

  closeCreateThemeModal() {
    this.isShowThemeModal = false;
  }

  renameGroup(title: string) {
    this.chatDataService.updateGroup({
      section: this.chatSectionSelected.name,
      title,
      group_id: this.group.id,
    });
    this.isShowRenameModal = false;
  }

  createTheme(title: string) {
    const users: number[] = [this.user.id];
    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.addTopic({
          section: this.chatSectionSelected.name,
          group_id: this.group.id,
          title,
          users,
        });
        break;

      // Вроде как не тут это делается
      // case ChatSectionsEnum.TRADE:
      //   this.chatDataService.addTradeTopic({
      //     title: event,
      //     trade_id: this.group.trade_id,
      //     users: users
      //   })
      // break;

      case ChatSectionsEnum.TECH:
        this.chatDataService.addTechTopic({
          group_id: this.group.id,
          title,
          users,
          create_from: this.techType,
        });
        break;
    }

    this.isShowThemeModal = false;
  }

  onToggleGroupExpanded() {
    if (!this.themes.length) {
      return;
    }

    this.group.isGroupExpanded = !this.group.isGroupExpanded;
  }

  // Актуализировать или удалить метод
  canEditGroup() {
    return (
      this.userId === this.group.owner_id ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.TECH && this.userId === this.group.id) ||
      (this.chatSectionSelected.name === this.chatSectionsEnum.ADMIN && this.user.type === RolesEnum.SUPERUSER)
    );
  }

  totalUnreadCounter() {
    const nestedTheme: ChatRoom[] = [...this.themes];

    this.themes.forEach((theme) => {
      const findNestedTheme = this.allThemes.find((item) => item.group_id === theme.id);
      if (findNestedTheme) {
        nestedTheme.push(findNestedTheme);
      }
    });

    if (this.chatSectionSelected.name !== this.chatSectionsEnum.TRADE) {
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([this.group]);
    } else {
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount(nestedTheme);
    }
  }

  get isOwner() {
    return !(this.userId === this.group.owner_id || this.user.type === RolesEnum.SUPERUSER);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
