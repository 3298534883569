import { Component, Input, OnInit } from '@angular/core';
import { UserCompetenceMapModalComponent } from '@app/+competence-map/competence-shared/user-competence-map/user-competence-map-modal/user-competence-map-modal.component';
import { ChatSectionsEnum } from '@app/chat/constants/chat-sections.constants';
import { ChatRoom } from '@app/chat/models/chat.model';
import { ChatService } from '@app/chat/services/chat.service';
import { DestroyService } from '@app/services/destroy.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { UserCardComponent } from '@app/shared/components/user-card/user-card.component';
import { ROLES, RolesEnum } from '@app/shared/constants/roles.constants';
import { User } from '@app/shared/models/user.model';
import { AuthService } from '@app/shared/services/auth.service';
import { UserTypes } from '@app/shared/types/user.types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.scss'],
})
export class ChatContactComponent implements OnInit {
  @Input() contact: ChatRoom;
  /* -------------------------------------------------------------------------- */
  // для дерева
  @Input() isTree = false;
  @Input() treeLevel: number = 0;
  /* -------------------------------------------------------------------------- */

  @Input() chatSectionName: ChatSectionsEnum | null = null;

  isExpanded = false;
  treeLastLevel = 0;
  scrollPosition = 0;
  listWidth = 310;

  roles = ROLES;
  rolesEnum = RolesEnum;

  userType: UserTypes;

  contactSelected: ChatRoom;

  isContactTooltipDisabled(name: string, role: string) {
    return name.length + role.length < 30 || this.isTree;
  }

  onContactSelect(contact: ChatRoom) {
    this.chatService.setRoomSelected(contact);
    this.chatService.focusChanged.next(true);
  }

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private modalService: NgbModal,
    private destroy$: DestroyService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (this.isTree) {
      this.chatService.scrollPosition.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.scrollPosition = res;
      });

      this.isExpanded = this.chatService.isUserExpanded(this.contact?.id);
    }

    this.contactSelected = this.chatService.getContactSelected();
    this.chatService.contactSelectedChanged.pipe(takeUntil(this.destroy$)).subscribe((contactSelected) => {
      this.contactSelected = contactSelected;
    });

    this.userType = this.authService.user_type;
  }

  showCard(user: User): void {
    const modal = this.modalService.open(UserCardComponent, {
      windowClass: 'dc-modal modal-window dc-modal-user-card',
      animation: true,
    });
    (<UserCardComponent>modal.componentInstance).user = user;
    (<UserCardComponent>modal.componentInstance).closeButton = true;
  }

  showCompetence(user: User): void {
    const modal = this.modalService.open(UserCompetenceMapModalComponent, {
      centered: true,
      animation: true,
      windowClass: 'dc-modal modal-window',
      size: 'md',
    });

    (<UserCompetenceMapModalComponent>modal.componentInstance).user = user;
  }

  onContactsExpand() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.chatService.addUserExpanded(Number(this.contact?.id));
    } else {
      this.chatService.removeUserExpanded(Number(this.contact?.id));
    }
  }
}
