import { Component, OnInit } from '@angular/core';
import { ChatSectionsEnum, GroupSortingNames, TradeRoomsTypes } from '../constants/chat-sections.constants';
import { ChatGroupOrder, ChatRoom } from '../models/chat.model';
import { ChatService } from '../services/chat.service';
import { AuthService } from '@app/shared/services/auth.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyService } from '@app/services/destroy.service';

@Component({
  selector: 'app-trade-chat',
  templateUrl: './trade-chat.component.html',
  styleUrls: ['./trade-chat.component.scss'],
})
export class TradeChatComponent implements OnInit {
  tradeRoomsTypes = TradeRoomsTypes;
  isCustomerExpanded: boolean = true;
  isProviderExpanded: boolean = true;
  isChoosingSortDirection = false;
  contactSelected: ChatRoom;

  totalUnreadMessagesCounterCustomer: number | '10+';
  totalUnreadMessagesCounterProvider: number | '10+';

  orderTypeDirections: ChatGroupOrder[] = [
    {
      name: GroupSortingNames.LAST_MESSAGE_DATE,
      label: 'с новыми сообщениями сначала',
      directions: [],
    },
    {
      name: GroupSortingNames.TITLE,
      label: 'по алфавиту',
      directions: [],
    },
    {
      name: GroupSortingNames.OWNER,
      label: 'мои группы сначала',
      directions: [
        {
          name: GroupSortingNames.LAST_MESSAGE_DATE,
          label: 'с новыми сообщениями сначала',
          parent: GroupSortingNames.OWNER,
        },
        {
          name: GroupSortingNames.CREATED_DATE,
          label: 'по дате создания группы',
          parent: GroupSortingNames.OWNER,
        },
        {
          name: GroupSortingNames.TITLE,
          label: 'по алфавиту',
          parent: GroupSortingNames.OWNER,
        },
      ],
    },
  ];
  chatSectionsEnum = ChatSectionsEnum;

  order: ChatGroupOrder;

  groups: ChatRoom[] = [];
  customerGroups: ChatRoom[] = [];
  providerGroups: ChatRoom[] = [];

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private chatDataService: SocketDataService,
    private ngUnsubscribe: DestroyService
  ) {}

  ngOnInit(): void {
    this.filterGroups(this.chatService.getGroups());
    this.filterThemes(this.chatService.getThemes());

    this.chatService.groupsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groups) => this.filterGroups(groups));

    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterThemes(this.chatService.getThemes());
    });

    this.setOrderType();

    this.contactSelected = this.chatService.getContactSelected();

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));
  }

  onToggleExpanded(group: string) {
    if (group === this.tradeRoomsTypes.CUSTOMER) {
      this.isCustomerExpanded = !this.isCustomerExpanded;
    }

    if (group === this.tradeRoomsTypes.PROVIDER) {
      this.isProviderExpanded = !this.isProviderExpanded;
    }
  }

  setOrderType() {
    this.resetOrders();
    this.order = this.orderTypeDirections[0];
    this.order.selected = true;
  }

  onSortDirectionChange(event: Event, order: ChatGroupOrder) {
    event.stopPropagation();

    if ((order.directions && !order.directions.length) || !order.directions) {
      this.isChoosingSortDirection = false;
    }

    this.resetOrders();

    order.selected = true;
    if (order.parent) {
      this.orderTypeDirections.forEach((d) => {
        if (d.name === order.parent) {
          d.selected = true;
        }
      });
    }

    this.order = order;
  }

  onSortDirectionNext() {
    this.isChoosingSortDirection = !this.isChoosingSortDirection;
  }

  resetOrders() {
    this.orderTypeDirections.forEach((d) => {
      d.selected = false;
      d.directions.forEach((item) => (item.selected = false));
    });
  }

  filterGroups(groups: ChatRoom) {
    this.groups = [];
    Object.keys(groups).forEach((room_id) => {
      const group: ChatRoom = groups[room_id];
      this.groups.push(group);
    });

    this.splitGroups();
  }

  filterThemes(themes: ChatRoom) {
    const customerThemes: ChatRoom[] = [];
    const providerThemes: ChatRoom[] = [];

    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (this.customerGroups.find((item) => item.id === theme.group_id)) {
        customerThemes.push(theme);
      }
      if (this.providerGroups.find((item) => item.id === theme.group_id)) {
        providerThemes.push(theme);
      }
    });
    //вложенные темы
    Object.keys(themes).forEach((room_id) => {
      const theme = themes[room_id];
      if (customerThemes.find((item) => item.id === theme.group_id)) {
        customerThemes.push(theme);
      }
      if (providerThemes.find((item) => item.id === theme.group_id)) {
        providerThemes.push(theme);
      }
    });
    this.totalUnreadMessagesCounterCustomer = this.chatService.totalUnreadCount([...customerThemes]);
    this.totalUnreadMessagesCounterProvider = this.chatService.totalUnreadCount([...providerThemes]);
  }

  splitGroups() {
    const selfId = Number(this.authService.user_id);
    this.customerGroups = [];
    this.providerGroups = [];

    this.groups.map((item) => {
      if (item.customers?.find((id) => id === selfId)) {
        this.customerGroups.push(item);
      }
      if (item.providers?.find((id) => id === selfId)) {
        this.providerGroups.push(item);
      }
    });
  }
}
