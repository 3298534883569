export enum PaymentTabEnum {
  REGISTRY = 'payment_register',
  WALLET = 'wallet_balance_report',
  ACCRUALS = 'transactions',
}

export enum PaymentToolbarControlEnum {
  SETTING = 'setting',
  UPLOAD_PAYMENTS = 'upload_payments',
  MANUAL_MODE = 'manual_mode',
  REVERSE_PAYMENT = 'reverse_payment',
  DOWNLOADED_FILES = 'downloaded_files',
  PAY = 'pay',
  PRINT = 'print',
  SAVE_AS = 'save_as',
  HELP = 'help',
}

export interface PaymentTab {
  title: string;
  value: PaymentTabEnum;
  icon?: string;
}

export const PAYMENT_TABS: PaymentTab[] = [
  { title: 'Общий реестр', value: PaymentTabEnum.REGISTRY, icon: 'list-ul' },
  { title: 'Отчет по балансам кошельков', value: PaymentTabEnum.WALLET, icon: 'balance-scale' },
  { title: 'Начисления и платежи', value: PaymentTabEnum.ACCRUALS, icon: 'ruble-sign' },
];

export const PAYMENT_TABS_SHORT: PaymentTab[] = [
  { title: 'Общий реестр', value: PaymentTabEnum.REGISTRY, icon: 'list-ul' },
  { title: 'Отчет по балансам кошельков', value: PaymentTabEnum.WALLET, icon: 'balance-scale' },
];
