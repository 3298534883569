<div
  (click)="onThemeSelect(theme)"
  [routerLink]="theme.room_id"
  [class.chat_active]="contactSelected.room_id === theme.room_id || themeSelected?.room_id === theme?.room_id"
  [style]="'--after-width:' + (310 + scrollPosition) + 'px'"
  class="theme d-flex"
  [class.trade-theme]="chatSectionSelected.name === chatSectionsEnum.TRADE"
  [class.trade-theme-last]="isTradeChatEndLevel"
>
  <button
    *ngIf="isTradeChatFirstLevel"
    (click)="$event.stopPropagation(); onToggleExpanded()"
    class="icon-button chat-expansion__caret"
    [class.disabled]="!themes.length"
  >
    <app-svg-icon [name]="group.isExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
  </button>
  <div class="theme-title">
    <div
      class="theme-title-text"
      [class.theme-title--long]="
        chatSectionSelected.name !== chatSectionsEnum.ADMIN && chatSectionSelected.name !== chatSectionsEnum.TRADE
      "
    >
      <span
        [class.text-overflow]="
          chatSectionSelected.name === chatSectionsEnum.ADMIN || chatSectionSelected.name === chatSectionsEnum.TRADE
        "
        [class.trade-nested-title]="isNested"
        [title]="theme.title"
      >
        {{ theme.title }}</span
      >
    </div>
  </div>
  <div
    class="d-flex align-items-center theme-menu"
    [style.marginLeft]="scrollPosition + 'px'"
    [class.info__selected]="contactSelected.room_id === theme.room_id || themeSelected?.room_id === theme?.room_id"
  >
    <button
      class="icon-button cog-button"
      [class.disabled]="isOwner || (isProvider && group?.type === tradeRoomsTypes.PROVIDER_CUSTOMER)"
      [class.chat-trade-cog]="isTradeChatEndLevel"
      type="button"
      (click)="$event.stopPropagation()"
      placement="left"
      [ngbPopover]="settingsContent"
      container="body"
      *ngIf="isTradeChatEndLevel || chatSectionSelected.name !== chatSectionsEnum.TRADE"
    >
      <app-svg-icon [name]="'cog'" width="15" height="15"></app-svg-icon>
    </button>
    <div [class.hidden]="!theme.menuExpanded"></div>
    <button
      class="icon-button"
      [disabled]="
        (isProvider && theme?.type === tradeRoomsTypes.PROVIDER_CUSTOMER) || userStatus !== statusesEnum.ACTIVE
      "
      (click)="openCreateModal()"
      [class.chat-trade-plus]="isTradeChatFirstLevel"
      *ngIf="isTradeChatFirstLevel"
    >
      <app-svg-icon [name]="'plus'" width="13" height="13"></app-svg-icon>
    </button>
    <span
      [style.visibility]="totalUnreadMessagesCounter ? 'visible' : 'hidden'"
      [class.chat-number__10]="totalUnreadMessagesCounter === '10+'"
      [class.trade-chat-number]="isTradeChatFirstLevel"
      [class.trade-chat-number-end]="isTradeChatEndLevel"
      class="chat-number theme-chat__number"
      >{{ totalUnreadMessagesCounter }}</span
    >
  </div>
</div>

<ng-container *ngIf="isExpanded">
  <div *ngFor="let item of themes">
    <app-chat-theme
      *ngIf="group.isGroupExpanded"
      [theme]="item"
      [group]="theme"
      [userId]="userId"
      [userType]="user?.type"
      [contactSelected]="contactSelected"
      [isNested]="true"
    ></app-chat-theme>
  </div>
</ng-container>

<app-modal-window [isShowed]="isReallyRemoving" (closeEvent)="isReallyRemoving = false" centered="true">
  <p class="mb-4">Вы действительно хотите удалить тему?</p>
  <p class="mb-5 text-overflow">Восстановить удаленные данные будет невозможно!</p>
  <div class="d-flex justify-content-between">
    <button class="btn btn-dc-outline" (click)="isReallyRemoving = false">Нет, отменить</button>
    <button class="btn btn-dc-primary" (click)="onRemoveTheme()">Да, применить</button>
  </div>
</app-modal-window>

<app-modal-window [isShowed]="isShowRenameModal" (closeEvent)="isShowRenameModal = false" centered="true">
  <app-chat-group-theme-name-form
    *ngIf="isShowRenameModal"
    [isShowed]="isShowRenameModal"
    [name]="theme.title"
    title="Переименовать тему"
    placeholder="Введите название темы"
    (cancelEdit)="closeModalName()"
    (saveName)="renameTheme($event)"
  ></app-chat-group-theme-name-form>
</app-modal-window>

<app-modal-window [isShowed]="isShowThemeModal" (closeEvent)="isShowThemeModal = false" centered="true">
  <app-chat-group-theme-name-form
    *ngIf="isShowThemeModal"
    [isShowed]="isShowThemeModal"
    title="Создать тему"
    placeholder="Введите название темы"
    (cancelEdit)="closeCreateTheme()"
    (saveName)="createTradeTopic($event)"
  ></app-chat-group-theme-name-form>
</app-modal-window>

<ng-template #settingsContent>
  <button class="settings-buttons" (click)="$event.stopPropagation(); openRenameThemeModal()">
    <app-svg-icon [name]="'edit_list'" width="15" height="15"></app-svg-icon>
    Переименовать тему
  </button>
  <button
    class="settings-buttons"
    [disabled]="group?.type === tradeRoomsTypes.PROVIDER_CUSTOMER"
    (click)="$event.stopPropagation(); onThemeEdit()"
  >
    <app-svg-icon [name]="'users'" width="15" height="15"></app-svg-icon>
    Редактировать тему
  </button>
  <button class="settings-buttons" (click)="$event.stopPropagation(); onRemoveThemeAsk()">
    <app-svg-icon [name]="'delete'" width="15" height="15"></app-svg-icon>
    Удалить тему
  </button>
</ng-template>
