<div class="d-flex align-items-center trade-item" (dblclick)="userDbClick()">
  <div class="mr-2 checkbox" *ngIf="!withoutSelection">
    <input
      [disabled]="
        item.disabled ||
        ownerId === +item.id ||
        item.type === rolesEnum.SUPERUSER ||
        (userId === +item.id && isRightPart)
      "
      class="cbx-input"
      [id]="isRightPart ? 'c' + item.id : item.id"
      type="checkbox"
      (change)="onCheckboxChange($event.target.checked)"
      [checked]="item.selected"
    />
    <label class="cbx-label" [for]="isRightPart ? 'c' + item.id : item.id"></label>
  </div>

  <div class="dc-competence-tree-item" [ngStyle]="{ 'padding-left': getContentOffset() + 'px' }">
    <div class="dc-competence-tree-item-inner">
      <div class="dc-competence-tree-item-heading">
        <app-svg-icon
          *ngIf="node?.children?.length"
          [name]="!node.isExpanded ? 'caret_down' : 'caret_up'"
          (click)="toggleNodeClicked()"
          [class.disabled]="!node?.children?.length || isItemChildrenInArchive"
          width="10"
        ></app-svg-icon>
        <div
          [disableTooltip]="node || textElement?.offsetWidth >= textElement?.scrollWidth"
          ngbTooltip="{{ item | initials }}, {{ item.type | titleRole : { isShort: true } }}"
        >
          <div
            #textElement
            class="dc-competence-tree-item-title trade-item__name text-overflow"
            [ngClass]="{ 'max-width-right': !node && isRightPart, 'max-width-left': !node && !isRightPart }"
            [class.inactive]="node?.data?.searchedInactive"
            [class.disabled]="item.disabled"
            [innerHtml]="
              (item | initials | highlight : searchString) +
              ' ' +
              (item.type | titleRole : { isShort: true } | toLowerCaseUserRole)
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dc-competence-tree-item-buttons" #controls>
  <!-- <div class="dc-competence-tree-item-status"></div> -->

  <div
    class="icons d-flex justify-content-end align-items-center trade-item__controls"
    *ngIf="placementIcons === 'right'"
  >
    <span class="service-menu-wrapper" *ngIf="isRightPart && chatSectionSelected.name !== chatSectionsEnum.TRADE">
      <button
        class="access-select color-brand icon-button"
        (click)="openSelect($event)"
        [class.access-select-disabled]="+item?.id === ownerId || !isGroup"
      >
        <app-svg-icon [name]="accessRightIcon" width="16" height="15"></app-svg-icon>
      </button>

      <div class="service-menu" *ngIf="isAccessSelectOpen" appClickElsewhere (appClickElsewhere)="closeSelect($event)">
        <button class="icon-button" (click)="selectMenuItem($event, accessRights.OWNER)">
          <app-svg-icon name="crown" width="16"></app-svg-icon>
        </button>

        <button class="icon-button" [class.no-hover]="withoutSelection" (click)="closeSelect($event)">
          <app-svg-icon name="user" width="16" height="16"></app-svg-icon>
        </button>
      </div>
    </span>
    <button
      *ngIf="chatSectionSelected.name === chatSectionsEnum.TRADE && item?.id === ownerId"
      class="access-select color-brand icon-button"
      [class.access-select-disabled]="item?.id === ownerId || !isGroup"
    >
      <app-svg-icon [name]="accessRightIcon" width="16" height="15"></app-svg-icon>
    </button>

    <app-svg-icon [name]="accessRightIcon" width="18" *ngIf="withoutSelection" class="no-hover"></app-svg-icon>
    <app-svg-icon name="circle" width="16" [class]="statusIconClass" class="no-hover"></app-svg-icon>

    <button class="icon-button" (click)="showUserInfo()">
      <app-svg-icon name="id-card" width="18"></app-svg-icon>
    </button>

    <button
      class="icon-button"
      (click)="showCompetence(item)"
      [disabled]="item.type === rolesEnum.SUPERUSER || item.type === rolesEnum.ACCOUNTANT"
    >
      <app-svg-icon [width]="18" [height]="14" name="competence-card"></app-svg-icon>
    </button>
  </div>
</div>
