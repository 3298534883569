import { ChatLastMessageDateThemeInGroups } from './../models/chat.model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { ChatService } from '@app/chat/services/chat.service';
import { AuthService } from '@app/shared/services/auth.service';
import { CompanyNamePipe } from '@app/shared/pipes/company-name.pipe';
import { InitialsPipe } from '@app/shared/pipes/users/initials.pipe';
import { ChatSectionsEnum, GroupSortingNames, TechTypeEnum } from '@app/chat/constants/chat-sections.constants';
import { User } from '@app/shared/models/user.model';
import { ChatGroupOrder, ChatRoom, ChatSection } from '@app/chat/models/chat.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { takeUntil } from 'rxjs/operators';
import { SocketDataService } from '@app/services/socket-data.service';
import { GroupSortPipe } from '../pipes/group-sort.pipe';

@Component({
  selector: 'app-chat-groups',
  templateUrl: './chat-groups.component.html',
  styleUrls: ['./chat-groups.component.scss'],
})
export class ChatGroupsComponent implements OnInit, OnDestroy {
  // Для загрузки тех групп и контактов
  @Input() techType: TechTypeEnum | null = null;
  techTypeEnum = TechTypeEnum;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  broadcastForm: FormGroup;
  groups: ChatRoom[] = [];
  themes: ChatRoom[] = [];
  contactSelected;
  filterString: string;
  chatSectionSelected: ChatSection;
  user: User;
  isChoosingSortDirection = false;
  order: ChatGroupOrder;
  rolesEnum = RolesEnum;
  isGroupsExpanded = true;
  isDutyFilterMenuOpened = false;
  isDutyFilterModalOpened = false;
  rolesSelected: string[] = [];
  isShowNameModal = false;
  scrollPosition: number = 0;
  protected totalUnreadMessagesCounter: number | '10+';

  orderTypeDirections: ChatGroupOrder[] = [
    {
      name: GroupSortingNames.LAST_MESSAGE_DATE,
      label: 'с новыми сообщениями сначала',
      directions: [],
    },
    {
      name: GroupSortingNames.TITLE,
      label: 'по алфавиту',
      directions: [],
    },
    {
      name: GroupSortingNames.OWNER,
      label: 'мои группы сначала',
      directions: [
        {
          name: GroupSortingNames.LAST_MESSAGE_DATE,
          label: 'с новыми сообщениями сначала',
          parent: GroupSortingNames.OWNER,
        },
        {
          name: GroupSortingNames.CREATED_DATE,
          label: 'по дате создания группы',
          parent: GroupSortingNames.OWNER,
        },
        {
          name: GroupSortingNames.TITLE,
          label: 'по алфавиту',
          parent: GroupSortingNames.OWNER,
        },
      ],
    },
  ];
  chatSectionsEnum = ChatSectionsEnum;

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private companyNamePipe: CompanyNamePipe,
    private initialsPipe: InitialsPipe,
    private chatDataService: SocketDataService
  ) {}

  ngOnInit() {
    this.filterString = this.chatService.contactsFilter;
    this.chatSectionSelected = this.chatService.getChatSectionSelected();

    this.authService.userStream.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.user = user;
    });

    this.chatService.chatSectionSelectedChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((chatSectionSelected) => {
      this.chatSectionSelected = chatSectionSelected;
      this.setOrderType();
    });
    this.filterGroups(this.chatService.getGroups());
    this.chatService.contactsFilterChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filterString) => {
      this.filterString = filterString;
      this.filterGroups(this.chatService.getGroups());
    });

    this.themes = Object.values(this.chatService.getThemes()) as ChatRoom[];
    this.chatService.themesChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((themes) => {
      this.themes = Object.values(themes) as ChatRoom[];
      this.sortGroupsOnThemeChanged();
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([...this.groups, ...this.themes]);
    });

    this.chatService.groupsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((groups) => {
      this.filterGroups(groups);
      this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([...this.groups, ...this.themes]);
    });

    this.contactSelected = this.chatService.getContactSelected();

    this.chatService.contactSelectedChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((contactSelected) => (this.contactSelected = contactSelected));

    this.initForm();
    this.setOrderType();

    this.chatService.scrollPosition.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.scrollPosition = res;
    });

    this.totalUnreadMessagesCounter = this.chatService.totalUnreadCount([...this.groups, ...this.themes]);
  }

  initForm() {
    this.broadcastForm = new FormGroup({
      content: new FormControl(null, Validators.required),
      superuser: new FormControl(null),
      accountant: new FormControl(null),
      expert: new FormControl(null),
      partner: new FormControl(null),
      admin_of_user: new FormControl(null),
      admin_of_direction: new FormControl(null),
      operator: new FormControl(null),
    });
  }

  setOrderType() {
    this.resetOrders();
    this.order = this.orderTypeDirections[0];
    this.order.selected = true;
  }

  filterGroups(groups) {
    this.groups = [];
    Object.keys(groups).forEach((room_id) => {
      const group: ChatRoom = groups[room_id];

      // Поудалять ненужные методы
      // if (this.chatSectionSelected.name === ChatSectionsEnum.DUTY_TSO) {
      //   const owner = group.userItems.find((user) => user.id === +group.owner_id);
      //group.title = `${this.initialsPipe.transform(owner)}`;
      // } else if (this.chatSectionSelected.name === ChatSectionsEnum.TECH) {
      //   const owner = group.userItems.find((user) => user.id === +group.owner_id);
      //   const company = this.chatService.companies[owner.company_id];

      // group.title = `ID-${owner.id}, ${this.companyNamePipe.transform(company)}, ${this.initialsPipe.transform(
      //   owner
      // )}`;
      //}

      if (!!this.filterString) {
        if (group.title.includes(this.filterString)) {
          this.groups.push(group);
        }
      } else {
        this.groups.push(group);
      }
    });
    // this.groups = this.chatService.sortRoomsByMessageActivity(this.groups);
    this.sortGroupsOnThemeChanged();
  }

  sortGroupsOnThemeChanged() {
    let lastMessageThemeDate: ChatLastMessageDateThemeInGroups[] = [];
    lastMessageThemeDate = this.groups.map((item) => {
      const findThemes = [...this.themes].filter((theme) => theme.group_id === item.id);
      const sortThemes = this.chatService.sortRoomsByMessageActivity(findThemes);
      return {
        id: item.id,
        theme_last_reed_message_date: sortThemes[0]?.last_read_message_date_read,
        first_not_read_theme_message_creation_date_in_chat: sortThemes[0]?.first_not_read_message_creation_date_in_chat,
        created_at: sortThemes[0]?.created_at,
      };
    });
    this.groups = this.chatService.sortRoomsByMessageActivity(this.groups, lastMessageThemeDate);
  }

  openGroupNameModal() {
    this.isShowNameModal = true;
  }

  closeModalName() {
    this.isShowNameModal = false;
  }

  createGroup(event: string) {
    const users: number[] = [this.user.id];

    switch (this.chatSectionSelected.name) {
      case ChatSectionsEnum.ADMIN:
      case ChatSectionsEnum.HOLDING:
        this.chatDataService.addGroup({
          section: this.chatSectionSelected.name,
          title: event,
          users: users,
        });
        break;
      case ChatSectionsEnum.TECH:
        this.chatDataService.addTechGroup({
          create_from: this.techType,
          title: event,
          users: users,
        });
    }
    this.isShowNameModal = false;
  }

  resetOrders() {
    this.orderTypeDirections.forEach((d) => {
      d.selected = false;
      d.directions.forEach((item) => (item.selected = false));
    });
  }

  onSortDirectionChange(event, order: ChatGroupOrder) {
    event.stopPropagation();

    if ((order.directions && !order.directions.length) || !order.directions) {
      this.isChoosingSortDirection = false;
    }

    this.resetOrders();

    order.selected = true;
    if (order.parent) {
      this.orderTypeDirections.forEach((d) => {
        if (d.name === order.parent) {
          d.selected = true;
        }
      });
    }

    this.order = order;
  }

  onSortDirectionNext() {
    this.isChoosingSortDirection = !this.isChoosingSortDirection;
  }

  onToggleGroupsExpanded() {
    this.isGroupsExpanded = !this.isGroupsExpanded;
    if (!this.isGroupsExpanded) {
      this.chatService.hideAllGroup();
    }
  }

  openDutyFilterMenu() {
    this.isDutyFilterMenuOpened = true;
  }

  closeDutyFilterMenu() {
    this.isDutyFilterMenuOpened = false;
  }

  // openDutyTsoFilter() {
  //   event.stopPropagation();
  //   this.isDutyFilterModalOpened = true;
  //   this.closeDutyFilterMenu();
  // }

  // closeDutyTsoFilter() {
  //   this.isDutyFilterModalOpened = false;
  //   this.closeDutyFilterMenu();
  // }

  // applyDutyTsoFilter(event) {
  //   this.rolesSelected = event;
  //   this.closeDutyTsoFilter();
  // }

  // resetDutyTsoFilter() {
  //   this.rolesSelected = [];
  //   this.closeDutyTsoFilter();
  // }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
