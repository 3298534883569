<div class="trade-chat">
  <div class="customer">
    <div class="d-flex align-items-center group-left">
      <button
        class="icon-button chat-expansion__caret"
        [class.disabled]="!groups.length"
        (click)="onToggleExpanded(tradeRoomsTypes.CUSTOMER)"
      >
        <app-svg-icon [name]="isCustomerExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
      </button>
      <span class="group-title">Торги заказчика</span>
      <div class="group__filters ml-2">
        <div class="sort__button">
          <button class="icon-button">
            <app-svg-icon [name]="'sort-amount-down'" width="12" height="12"></app-svg-icon>
          </button>
        </div>
        <div (click)="onSortDirectionNext()" class="sort__button">
          <ul *ngIf="isChoosingSortDirection" class="sort__direction-select list-group">
            <li
              class="list-group-item"
              *ngFor="let type of orderTypeDirections"
              (click)="onSortDirectionChange($event, type)"
            >
              <span [class.c-black]="type.selected">{{ type.label }}</span>
              <ul class="list-group" *ngIf="type.selected && type.directions?.length">
                <li
                  *ngFor="let direction of type.directions"
                  [class.c-black]="direction.selected"
                  (click)="onSortDirectionChange($event, direction)"
                >
                  {{ direction.label }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center group-right">
      <span class="icon-link">
        <button class="icon-button">
          <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
        </button>
      </span>

      <span
        class="chat-number"
        [style.visibility]="totalUnreadMessagesCounterCustomer ? 'visible' : 'hidden'"
        [class.chat-number__10]="totalUnreadMessagesCounterCustomer === '10+'"
        >{{ totalUnreadMessagesCounterCustomer }}</span
      >
    </div>
  </div>

  <div *ngIf="isCustomerExpanded" class="groups-list">
    <div *ngFor="let group of customerGroups | groupSort : order | groupsRolesFilter : rolesSelected">
      <app-chat-group [group]="group" [contactSelected]="contactSelected"></app-chat-group>
    </div>
  </div>

  <hr />
  <div class="provider">
    <div class="d-flex align-items-center group-left">
      <button
        class="icon-button chat-expansion__caret"
        [class.disabled]="!groups.length"
        (click)="onToggleExpanded(tradeRoomsTypes.PROVIDER)"
      >
        <app-svg-icon [name]="isCustomerExpanded ? 'caret_up' : 'caret_down'" width="12" height="12"></app-svg-icon>
      </button>
      <span class="group-title">Торги поставщика</span>
      <div class="group__filters ml-2">
        <div class="sort__button">
          <button class="icon-button">
            <app-svg-icon [name]="'sort-amount-down'" width="12" height="12"></app-svg-icon>
          </button>
        </div>
        <div (click)="onSortDirectionNext()" class="sort__button">
          <ul *ngIf="isChoosingSortDirection" class="sort__direction-select list-group">
            <li
              class="list-group-item"
              *ngFor="let type of orderTypeDirections"
              (click)="onSortDirectionChange($event, type)"
            >
              <span [class.c-black]="type.selected">{{ type.label }}</span>
              <ul class="list-group" *ngIf="type.selected && type.directions?.length">
                <li
                  *ngFor="let direction of type.directions"
                  [class.c-black]="direction.selected"
                  (click)="onSortDirectionChange($event, direction)"
                >
                  {{ direction.label }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center group-right">
      <span class="icon-link">
        <button class="icon-button">
          <app-svg-icon [name]="'question-circle-color'" width="16" height="16"></app-svg-icon>
        </button>
      </span>

      <span
        class="chat-number"
        [style.visibility]="totalUnreadMessagesCounterProvider ? 'visible' : 'hidden'"
        [class.chat-number__10]="totalUnreadMessagesCounterProvider === '10+'"
        >{{ totalUnreadMessagesCounterProvider }}</span
      >
    </div>
  </div>

  <div *ngIf="isCustomerExpanded" class="groups-list">
    <div *ngFor="let group of providerGroups | groupSort : order | groupsRolesFilter : rolesSelected">
      <app-chat-group [group]="group" [contactSelected]="contactSelected"></app-chat-group>
    </div>
  </div>
</div>
