import { Column, PaymentRegistryRequestConfig } from '../models/payment-registry-column.model';
import { RolesEnum } from '@app/shared/constants/roles.constants';
import { MultiSelectListItem } from '@app/shared/models/multi-select-list-item';
import { StatusesEnum } from '@app/shared/constants/statuses.constants';
import { CommentsEnum } from '@app/shared/enums/comments';
import { RemovingRestrictionEnum } from '@app/shared/enums/removing-restriction';

export enum PaymentRegistryColumnsKeys {
  REPORTING_PERIOD = 'reporting_period',
  ID_TRADE = 'id_trade',
  STRUCTURE_AP = 'structure_ap',
  PAYER = 'payer',
  CUSTOMER_TRADE = 'customer_trade',
  RESPONSIBLE_FOR_TRADE = 'responsible_for_trade',
  COMPANY_STATUSES = 'company_statuses',
  REMOVING_RESTRICTIONS = 'removing_restrictions',
  COMMENT = 'comment',
  TARIFF_NAME = 'tariff_name',
  TARIFF_LOT = 'tariff_one_lot',
  CUSTOMER_DISCOUNT = 'customer_discount',
  COMPLETED_TRADE = 'completed_trade',
  CUSTOMER_PAYMENTS = 'customer_payments',
  DOCUMENTS = 'documents',
  PAYMENT_INFO = 'payment_info',
  CUSTOMER_BALANCE = 'customer_balance',
  AGENTS_BONUS_LOT = 'agent_bonus_lot',
  CALCULATION_BONUS_LOTS = 'calculation_bonus_lots',
  BONUSES_LOTS_PAY = 'bonuses_lots_pay',
  BONUSES_PERCENTAGE_LOTS = 'bonus_percentage_lots',
  AGENT_ID_LOT = 'id_agent_lot',
  FIO_AGENT_LOT = 'fio_agent_lot',
  BONUSES_AGENT_OTP = 'bonuses_agent_otp',
  CALCULATION_BONUS_OTP = 'calculation_bonus_otp',
  BONUSES_OTP_PAY = 'bonuses_otp_pay',
  BONUSES_PERCENTAGE_OTP = 'bonus_percentage_otp',
  ID_AGENT_OTP = 'id_agent_otp',
  FIO_AGENT_OTP = 'fio_agent_otp',
  BONUS_PERCENT_AGENT_NET = 'bonus_percent_agent_net',
  CALCULATION_BONUS_NET = 'calculation_bonus_net',
  BONUSES_NET_PAY = 'bonuses_net_pay',
  BONUSES_PERCENTAGE_NET = 'bonus_percentage_net',
  AGENT_ID_NET = 'id_agent_net',
  FIO_AGENT_NET = 'fio_agent_net',
  AMOUNT_CALCULATED_BONUSES = 'amount_calculated_bonuses',
  AGENT_BONUSES_AMOUNT_PAYABLE = 'agent_bonus_amount_payable',
  PERCENTAGE_ALL_BONUSES_PAYABLE = 'percentage_all_bonuses_payable',
  BONUS_AMOUNT_PAYABLE_MINUS_NDFL = 'bonus_amount_payable_minus_ndfl',
}

export enum PaymentRegistryFilterKeys {
  ID_TRADE = 'trade_id',
  STRUCTURE_AP = 'structure_ap.company.id',
  PAYER = 'payer.company.id',
  CUSTOMER_TRADE = 'customer.company.id',
  RESPONSIBLE_FOR_TRADE = 'responsible_for_trade_id',
  COMPANY_STATUSES = 'company_statuses',
  REMOVING_RESTRICTIONS = 'payer._permission_to_trade',
  COMMENT = 'comment',
  TARIFF_NAME = 'tariff_id',
  TARIFF_LOT = 'tariff_one_lot',
  CUSTOMER_DISCOUNT = 'customer_discount',
  COMPLETED_TRADE = 'calculation_bonus_lots',
  CUSTOMER_PAYMENTS = 'customer_payment',
  PAYMENT_INFO = 'payment_info',
  CUSTOMER_BALANCE = 'customer_balance',
  AGENTS_BONUS_LOT = 'agent_bonus_lot',
  CALCULATION_BONUS_LOTS = 'calculation_bonus_lots',
  BONUSES_LOTS_PAY = 'bonuses_lots_pay',
  BONUSES_PERCENTAGE_LOTS = 'bonus_percentage_lots',
  AGENT_ID_LOT = 'agent_id',
  FIO_AGENT_LOT = 'agent.id',
  BONUSES_AGENT_OTP = 'bonuses_agent_otp',
  CALCULATION_BONUS_OTP = 'calculation_bonus_otp',
  BONUSES_OTP_PAY = 'bonuses_otp_pay',
  BONUSES_PERCENTAGE_OTP = 'bonus_percentage_otp',
  ID_AGENT_OTP = 'tso_id',
  FIO_AGENT_OTP = 'tso.id',
  BONUS_PERCENT_AGENT_NET = 'bonus_percent_agent_net',
  CALCULATION_BONUS_NET = 'calculation_bonus_net',
  BONUSES_NET_PAY = 'bonuses_net_pay',
  BONUSES_PERCENTAGE_NET = 'bonus_percentage_net',
  AGENT_ID_NET = 'agent_net_id',
  FIO_AGENT_NET = 'agent_net.id',
  AMOUNT_CALCULATED_BONUSES = 'amount_calculated_bonuses',
  AGENT_BONUSES_AMOUNT_PAYABLE = 'agent_bonus_amount_payable',
  PERCENTAGE_ALL_BONUSES_PAYABLE = 'percentage_all_bonuses_payable',
  BONUS_AMOUNT_PAYABLE_MINUS_NDFL = 'bonus_amount_payable_minus_ndfl',
}

export const paymentRegistryColumns: Column<PaymentRegistryRequestConfig>[] = [
  {
    id: PaymentRegistryColumnsKeys.REPORTING_PERIOD,
    label: 'Отчетный период',
    width: 140,
    filterKeys: ['from_reporting_period', 'to_reporting_period'],
    isEnabledFilter: true,
    isDisabledCheckbox: true,
    data: {
      isSticky: true,
      indent: 0,
    },
    filterConfig: {
      from_reporting_period: null,
      to_reporting_period: null,
    },
  },
  {
    id: PaymentRegistryColumnsKeys.ID_TRADE,
    label: 'ID торгов',
    width: 130,
    isEnabledSearch: true,
    isDisabledCheckbox: true,
    placeholder: 'Начните вводить ID торгов',
    data: {
      isSticky: true,
      indent: 140,
    },
    filterKey: PaymentRegistryFilterKeys.ID_TRADE,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.ID_TRADE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.STRUCTURE_AP,
    label: 'Администратор пользователя',
    width: 280,
    isEnabledSearch: true,
    isDisabledCheckbox: true,
    placeholder: 'Начните вводить администора пользователя',
    data: {
      isSticky: true,
      indent: 270,
    },
    filterKey: PaymentRegistryFilterKeys.STRUCTURE_AP,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.STRUCTURE_AP,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.PAYER,
    label: 'Плательщик',
    width: 250,
    isEnabledSearch: true,
    placeholder: 'Начните вводить плательщика',
    filterKey: PaymentRegistryFilterKeys.PAYER,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.PAYER,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CUSTOMER_TRADE,
    label: 'Заказчики торгов',
    width: 245,
    isEnabledSearch: true,
    placeholder: 'Начните вводить заказчика торгов',
    filterKey: PaymentRegistryFilterKeys.CUSTOMER_TRADE,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.CUSTOMER_TRADE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.RESPONSIBLE_FOR_TRADE,
    label: 'Ответственные за торги',
    width: 230,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ответственного за торги',
    filterKey: PaymentRegistryFilterKeys.RESPONSIBLE_FOR_TRADE,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.RESPONSIBLE_FOR_TRADE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.COMPANY_STATUSES,
    label: 'Статус',
    width: 125,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.COMPANY_STATUSES,
    filterKeys: ['company_statuses'],
    filterConfig: {
      company_statuses: null,
    },
  },
  {
    id: PaymentRegistryColumnsKeys.REMOVING_RESTRICTIONS,
    label: 'Снятие ограничений',
    width: 140,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.REMOVING_RESTRICTIONS,
    filterKeys: ['removing_restrictions'],
    filterConfig: {
      removing_restrictions: null,
    },
  },
  {
    id: PaymentRegistryColumnsKeys.COMMENT,
    label: 'Комментарии',
    width: 160,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.COMMENT,
    filterKeys: ['comment'],
    filterConfig: {
      comment: null,
    },
  },
  {
    id: PaymentRegistryColumnsKeys.TARIFF_NAME,
    label: 'Название тарифа',
    width: 200,
    isEnabledSearch: true,
    placeholder: 'Начните вводить название тарифа',
    filterKey: PaymentRegistryFilterKeys.TARIFF_NAME,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.TARIFF_NAME,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.TARIFF_LOT,
    label: 'Тариф за 1 лот (₽)',
    width: 122,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.TARIFF_LOT,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.TARIFF_LOT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CUSTOMER_DISCOUNT,
    label: 'Скидка клиента по промо-коду (₽)',
    width: 183,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.CUSTOMER_DISCOUNT,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.CUSTOMER_DISCOUNT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.COMPLETED_TRADE,
    label: 'Завершенные торги (лоты)',
    width: 170,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.COMPLETED_TRADE,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.COMPLETED_TRADE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CUSTOMER_PAYMENTS,
    label: 'Суммы к оплате клиентами (₽)',
    width: 180,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.CUSTOMER_PAYMENTS,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.CUSTOMER_PAYMENTS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.DOCUMENTS,
    label: 'Документы',
    width: 110,
  },
  {
    id: PaymentRegistryColumnsKeys.PAYMENT_INFO,
    label: 'Даты оплат, № п/п, оплаченная сумма (₽)',
    width: 350,
    isEnabledSearch: true,
    filterKey: PaymentRegistryFilterKeys.PAYMENT_INFO,
    filterKeys: ['from_payment_date', 'to_payment_date'],
    filterConfig: {
      from_payment_date: null,
      to_payment_date: null,
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CUSTOMER_BALANCE,
    label: 'Баланс клиентов (₽)',
    width: 148,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.CUSTOMER_BALANCE,
    filterKeys: ['from_customer_balance', 'to_customer_balance'],
    filterConfig: {
      from_customer_balance: null,
      to_customer_balance: null,
    },
  },
  {
    id: PaymentRegistryColumnsKeys.AGENTS_BONUS_LOT,
    label: 'Бонус агента за 1 лот (₽)',
    width: 149,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.AGENTS_BONUS_LOT,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.AGENTS_BONUS_LOT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CALCULATION_BONUS_LOTS,
    label: 'Расчет бонуса за лоты (₽)',
    width: 158,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.CALCULATION_BONUS_LOTS,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.CALCULATION_BONUS_LOTS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_LOTS_PAY,
    label: 'Бонусы за лоты к оплате (₽)',
    width: 169,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_LOTS_PAY,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_LOTS_PAY,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_LOTS,
    label: '% бонусов за лоты к оплате',
    width: 157,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_PERCENTAGE_LOTS,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_LOTS,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.AGENT_ID_LOT,
    label: 'ID агента за лоты',
    width: 129,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ID агента за лоты',
    filterKey: PaymentRegistryFilterKeys.AGENT_ID_LOT,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.AGENT_ID_LOT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.FIO_AGENT_LOT,
    label: 'ФИО агента за лоты',
    width: 160,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ФИО агента за лоты',
    filterKey: PaymentRegistryFilterKeys.FIO_AGENT_LOT,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.FIO_AGENT_LOT,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_AGENT_OTP,
    label: 'Бонус агента за ОТП (₽)',
    width: 149,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_AGENT_OTP,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_AGENT_OTP,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CALCULATION_BONUS_OTP,
    label: 'Расчет бонуса за ОТП (₽)',
    width: 158,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.CALCULATION_BONUS_OTP,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.CALCULATION_BONUS_OTP,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_OTP_PAY,
    label: 'Бонусы за ОТП к оплате (₽)',
    width: 162,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_OTP_PAY,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_OTP_PAY,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_OTP,
    label: '% бонусов за ОТП к оплате',
    width: 151,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_PERCENTAGE_OTP,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_OTP,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.ID_AGENT_OTP,
    label: 'ID агента-ОТП',
    width: 150,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ID агента-ОТП',
    filterKey: PaymentRegistryFilterKeys.ID_AGENT_OTP,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.ID_AGENT_OTP,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.FIO_AGENT_OTP,
    label: 'ФИО агента-ОТП',
    width: 180,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ФИО агента-ОТП',
    filterKey: PaymentRegistryFilterKeys.FIO_AGENT_OTP,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.FIO_AGENT_OTP,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUS_PERCENT_AGENT_NET,
    label: 'Бонус агента за сеть (%)',
    width: 149,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUS_PERCENT_AGENT_NET,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUS_PERCENT_AGENT_NET,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.CALCULATION_BONUS_NET,
    label: 'Расчет бонуса за сеть (₽)',
    width: 158,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.CALCULATION_BONUS_NET,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.CALCULATION_BONUS_NET,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_NET_PAY,
    label: 'Бонусы за сеть к оплате (₽)',
    width: 164,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_NET_PAY,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_NET_PAY,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_NET,
    label: '% бонусов за сеть к оплате',
    width: 153,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUSES_PERCENTAGE_NET,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_NET,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.AGENT_ID_NET,
    label: 'ID агента за сеть',
    width: 129,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ID агента за сеть',
    filterKey: PaymentRegistryFilterKeys.AGENT_ID_NET,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.AGENT_ID_NET,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.FIO_AGENT_NET,
    label: 'ФИО агента за сеть',
    width: 160,
    isEnabledSearch: true,
    placeholder: 'Начните вводить ФИО агента за сеть',
    filterKey: PaymentRegistryFilterKeys.FIO_AGENT_NET,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.FIO_AGENT_NET,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.AMOUNT_CALCULATED_BONUSES,
    label: 'Сумма рассчитанных бонусов (₽)',
    width: 206,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.AMOUNT_CALCULATED_BONUSES,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.AMOUNT_CALCULATED_BONUSES,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.AGENT_BONUSES_AMOUNT_PAYABLE,
    label: 'Сумма бонусов к оплате (₽)',
    width: 165,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.AGENT_BONUSES_AMOUNT_PAYABLE,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.AGENT_BONUSES_AMOUNT_PAYABLE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.PERCENTAGE_ALL_BONUSES_PAYABLE,
    label: '% всех бонусов к оплате',
    width: 164,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.PERCENTAGE_ALL_BONUSES_PAYABLE,
    filterConfig: {
      filter: {
        name: PaymentRegistryColumnsKeys.PERCENTAGE_ALL_BONUSES_PAYABLE,
        op: 'in_',
        val: '',
      },
    },
  },
  {
    id: PaymentRegistryColumnsKeys.BONUS_AMOUNT_PAYABLE_MINUS_NDFL,
    label: 'Бонус к оплате с вычетом НДФЛ (₽)',
    width: 195,
    isEnabledFilter: true,
    filterKey: PaymentRegistryFilterKeys.BONUS_AMOUNT_PAYABLE_MINUS_NDFL,
    filterKeys: ['from_bonus_amount_payable_minus_ndfl', 'to_bonus_amount_payable_minus_ndfl'],
    filterConfig: {
      from_bonus_amount_payable_minus_ndfl: null,
      to_bonus_amount_payable_minus_ndfl: null,
    },
  },
].map((column) => {
  return {
    ...column,
    defaultFilterConfig: {
      filter: column.filterConfig?.filter,
    },
  };
});

export const paymentRegistryColumnsPartner: string[] = [
  PaymentRegistryColumnsKeys.REPORTING_PERIOD,
  PaymentRegistryColumnsKeys.ID_TRADE,
  PaymentRegistryColumnsKeys.STRUCTURE_AP,
  PaymentRegistryColumnsKeys.PAYER,
  PaymentRegistryColumnsKeys.CUSTOMER_TRADE,
  PaymentRegistryColumnsKeys.RESPONSIBLE_FOR_TRADE,
  PaymentRegistryColumnsKeys.COMPANY_STATUSES,
  PaymentRegistryColumnsKeys.TARIFF_NAME,
  PaymentRegistryColumnsKeys.TARIFF_LOT,
  PaymentRegistryColumnsKeys.CUSTOMER_DISCOUNT,
  PaymentRegistryColumnsKeys.COMPLETED_TRADE,
  PaymentRegistryColumnsKeys.CUSTOMER_PAYMENTS,
  PaymentRegistryColumnsKeys.CUSTOMER_BALANCE,
  PaymentRegistryColumnsKeys.AGENTS_BONUS_LOT,
  PaymentRegistryColumnsKeys.CALCULATION_BONUS_LOTS,
  PaymentRegistryColumnsKeys.BONUSES_LOTS_PAY,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_LOTS,
  PaymentRegistryColumnsKeys.AGENT_ID_LOT,
  PaymentRegistryColumnsKeys.FIO_AGENT_LOT,
  PaymentRegistryColumnsKeys.BONUSES_AGENT_OTP,
  PaymentRegistryColumnsKeys.CALCULATION_BONUS_OTP,
  PaymentRegistryColumnsKeys.BONUSES_OTP_PAY,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_OTP,
  PaymentRegistryColumnsKeys.ID_AGENT_OTP,
  PaymentRegistryColumnsKeys.FIO_AGENT_OTP,
  PaymentRegistryColumnsKeys.BONUS_PERCENT_AGENT_NET,
  PaymentRegistryColumnsKeys.BONUSES_NET_PAY,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_NET,
  PaymentRegistryColumnsKeys.AGENT_ID_NET,
  PaymentRegistryColumnsKeys.FIO_AGENT_NET,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_NET,
  PaymentRegistryColumnsKeys.AMOUNT_CALCULATED_BONUSES,
  PaymentRegistryColumnsKeys.AGENT_BONUSES_AMOUNT_PAYABLE,
  PaymentRegistryColumnsKeys.PERCENTAGE_ALL_BONUSES_PAYABLE,
  PaymentRegistryColumnsKeys.BONUS_AMOUNT_PAYABLE_MINUS_NDFL,
];

export const paymentRegistryColumnsSuperuser: string[] = [
  PaymentRegistryColumnsKeys.REPORTING_PERIOD,
  PaymentRegistryColumnsKeys.ID_TRADE,
  PaymentRegistryColumnsKeys.STRUCTURE_AP,
  PaymentRegistryColumnsKeys.PAYER,
  PaymentRegistryColumnsKeys.CUSTOMER_TRADE,
  PaymentRegistryColumnsKeys.RESPONSIBLE_FOR_TRADE,
  PaymentRegistryColumnsKeys.COMPANY_STATUSES,
  PaymentRegistryColumnsKeys.REMOVING_RESTRICTIONS,
  PaymentRegistryColumnsKeys.COMMENT,
  PaymentRegistryColumnsKeys.TARIFF_NAME,
  PaymentRegistryColumnsKeys.TARIFF_LOT,
  PaymentRegistryColumnsKeys.CUSTOMER_DISCOUNT,
  PaymentRegistryColumnsKeys.COMPLETED_TRADE,
  PaymentRegistryColumnsKeys.CUSTOMER_PAYMENTS,
  PaymentRegistryColumnsKeys.DOCUMENTS,
  PaymentRegistryColumnsKeys.PAYMENT_INFO,
  PaymentRegistryColumnsKeys.CUSTOMER_BALANCE,
  PaymentRegistryColumnsKeys.AGENTS_BONUS_LOT,
  PaymentRegistryColumnsKeys.CALCULATION_BONUS_LOTS,
  PaymentRegistryColumnsKeys.BONUSES_LOTS_PAY,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_LOTS,
  PaymentRegistryColumnsKeys.AGENT_ID_LOT,
  PaymentRegistryColumnsKeys.FIO_AGENT_LOT,
  PaymentRegistryColumnsKeys.BONUSES_AGENT_OTP,
  PaymentRegistryColumnsKeys.CALCULATION_BONUS_OTP,
  PaymentRegistryColumnsKeys.BONUSES_OTP_PAY,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_OTP,
  PaymentRegistryColumnsKeys.ID_AGENT_OTP,
  PaymentRegistryColumnsKeys.FIO_AGENT_OTP,
  PaymentRegistryColumnsKeys.BONUS_PERCENT_AGENT_NET,
  PaymentRegistryColumnsKeys.CALCULATION_BONUS_NET,
  PaymentRegistryColumnsKeys.BONUSES_NET_PAY,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_NET,
  PaymentRegistryColumnsKeys.AGENT_ID_NET,
  PaymentRegistryColumnsKeys.FIO_AGENT_NET,
  PaymentRegistryColumnsKeys.BONUSES_PERCENTAGE_NET,
  PaymentRegistryColumnsKeys.AMOUNT_CALCULATED_BONUSES,
  PaymentRegistryColumnsKeys.AGENT_BONUSES_AMOUNT_PAYABLE,
  PaymentRegistryColumnsKeys.PERCENTAGE_ALL_BONUSES_PAYABLE,
  PaymentRegistryColumnsKeys.BONUS_AMOUNT_PAYABLE_MINUS_NDFL,
];

export const paymentRegistryColumnsClient: string[] = [
  PaymentRegistryColumnsKeys.REPORTING_PERIOD,
  PaymentRegistryColumnsKeys.ID_TRADE,
  PaymentRegistryColumnsKeys.STRUCTURE_AP,
  PaymentRegistryColumnsKeys.PAYER,
  PaymentRegistryColumnsKeys.CUSTOMER_TRADE,
  PaymentRegistryColumnsKeys.RESPONSIBLE_FOR_TRADE,
  PaymentRegistryColumnsKeys.COMPANY_STATUSES,
  PaymentRegistryColumnsKeys.REMOVING_RESTRICTIONS,
  PaymentRegistryColumnsKeys.TARIFF_NAME,
  PaymentRegistryColumnsKeys.TARIFF_LOT,
  PaymentRegistryColumnsKeys.CUSTOMER_DISCOUNT,
  PaymentRegistryColumnsKeys.COMPLETED_TRADE,
  PaymentRegistryColumnsKeys.CUSTOMER_PAYMENTS,
  PaymentRegistryColumnsKeys.DOCUMENTS,
  PaymentRegistryColumnsKeys.PAYMENT_INFO,
  PaymentRegistryColumnsKeys.CUSTOMER_BALANCE,
];

export const paymentRegistryColumnsByUserType = {
  [RolesEnum.PARTNER]: paymentRegistryColumnsPartner,
  [RolesEnum.ADMIN_OF_USER]: paymentRegistryColumnsClient,
  [RolesEnum.ADMIN_OF_DIRECTION]: paymentRegistryColumnsClient,
  [RolesEnum.ACCOUNTANT]: paymentRegistryColumnsSuperuser,
  [RolesEnum.SUPERUSER]: paymentRegistryColumnsSuperuser,
};

export const PAYMENT_REGISTRY: MultiSelectListItem[] = [
  {
    id: StatusesEnum.ACTIVE,
    name: 'Актив',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: false,
  },
  {
    id: StatusesEnum.VIEW,
    name: 'Блок',
    iconSvg: 'circle',
    classes: ['status-yellow'],
    checked: false,
  },
  {
    id: StatusesEnum.IN_ARCHIVE,
    name: 'Архив',
    icon: ['fas', 'circle'],
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
];

export const REMOVING_RESTRICTIONS = [
  {
    id: RemovingRestrictionEnum.FALSE,
    name: 'Без ограничений',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: false,
  },
  {
    id: RemovingRestrictionEnum.TRUE,
    name: 'С ограничениями',
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: true,
  },
];

export const COMMENTS = [
  {
    id: CommentsEnum.TRUE,
    name: 'С комментриями',
    iconSvg: 'circle',
    classes: ['status-green'],
    checked: true,
  },
  {
    id: CommentsEnum.FALSE,
    name: 'Без комментариев',
    iconSvg: 'circle',
    classes: ['status-red'],
    checked: false,
  },
];
