import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { AuthService } from '@app/shared/services/auth.service';
import { ChatService } from '@app/chat/services/chat.service';
import { SocketDataService } from '@app/services/socket-data.service';
import { AttachedFile, ChatMessage, ChatRoom } from '@app/chat/models/chat.model';
import { FileManagerService } from '@app/file_manager/services/file_manager.service';

import saveAs from 'file-saver';
import { User } from '@app/shared/models/user.model';
import { DestroyService } from '@app/services/destroy.service';
import { merge, Subject } from 'rxjs';
import { map, tap } from 'rxjs/internal/operators';
import { ActivatedRoute } from '@angular/router';
import { DEFAULT_MESSAGE_LOAD_COUNT } from '@app/shared/constants/chat.constants';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  providers: [DestroyService],
})
export class ChatMessagesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollMe') private scrollMe: ElementRef;
  @ViewChildren('messageElement') messageElements!: QueryList<ElementRef>;
  private intersectionObserver: IntersectionObserver;

  messages: ChatMessage[] = [];
  private room_id: string;
  roomSelected: ChatRoom;
  userId = Number(this.authService.user_id);
  protected isMessagesLoading: boolean = false;
  scrollHeight = 0;
  count: number;

  protected chatLoading = this.chatService.chatLoading.getValue();
  isTradeGroup = this.chatService.isTradeGroup;
  protected timezoneOffset = this.authService.getTimezoneOffset();

  private readonly expandedMessages = new Set<number>();

  private unreadMessageIds: number[] = [];
  private messageReadSubject = new Subject();

  constructor(
    private chatService: ChatService,
    private chatDataService: SocketDataService,
    private authService: AuthService,
    private fileManagerService: FileManagerService,
    private readonly destroy$: DestroyService,
    private route: ActivatedRoute
  ) {
    this.messageReadSubject
      .pipe(
        debounceTime(5000),
        tap(() => {
          const idsToMarkRead = new Set(this.unreadMessageIds);
          const maxId = Math.max(...idsToMarkRead);

          if (maxId > this.roomSelected.last_read_message_id) {
            this.chatDataService.setMessageRead(this.roomSelected.room_id, [...idsToMarkRead]);
          }

          this.unreadMessageIds = [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        filter(({ roomId }) => !!roomId),
        tap(() => {
          this.isMessagesLoading = true;
          this.messages = [];
          this.unreadMessageIds = [];
        }),
        map(({ roomId }) => {
          this.room_id = roomId;
          const currentMessages = this.chatService.getMessagesListByRoomId(roomId);

          if (currentMessages?.length > DEFAULT_MESSAGE_LOAD_COUNT) {
            this.roomSelected = this.chatService.findChatRoomById(roomId);
            this.updateMessages(roomId, currentMessages || []);
            this.updateScroll();

            return null;
          } else {
            return roomId;
          }
        }),
        filter((roomId: string | null) => !!roomId),
        switchMap((roomId) => {
          return this.chatDataService
            .loadPreviousMessages({ room_id: roomId })
            .pipe(map((res) => ({ ...res, roomId })));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        if (!data) return;

        const { items, count, roomId } = data;
        this.chatService.setPreviousMessages(items, count, roomId || '');
      });

    merge(this.chatService.contactsChanged, this.chatService.themesChanged, this.chatService.groupsChanged)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.roomSelected = this.chatService.findChatRoomById(this.room_id);
      });

    this.chatService.messagesChanged.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.roomSelected = this.chatService.findChatRoomById(this.room_id);
      if (!this.roomSelected) return;

      const messagesList = this.chatService.getMessagesListByRoomId(this.room_id);
      const isISentLastMessage = messagesList.at(-1)?.author_id === this.userId;

      this.updateMessages(this.room_id, messagesList);
      this.updateScroll(isISentLastMessage);
    });
  }

  ngAfterViewInit(): void {
    this.initializeIntersectionObserver();
  }

  ngOnDestroy() {
    this.intersectionObserver.disconnect();
  }

  private updateScroll(isMyMessageLast: boolean = false) {
    if (this.isMessagesLoading) {
      this.isMessagesLoading = false;
      setTimeout(() => {
        if (!this.roomSelected) return;
        if (this.roomSelected.last_read_message_id == null) this.scrollToBottom();
        if (this.roomSelected.last_read_message_id === 0) this.scrollToTop();
        if (this.roomSelected.last_read_message_id > 0) this.scrollToUnread();
      }, 0);
    } else {
      if (this.isScrolledToBottom() || isMyMessageLast) {
        setTimeout(() => this.scrollToBottom(), 0);
      }
    }
  }

  private scrollToTop() {
    const container = this.scrollMe.nativeElement;
    container.scrollTop = 0;
  }

  private scrollToBottom() {
    const container = this.scrollMe.nativeElement;
    container.scrollTop = container.scrollHeight;
  }

  private scrollToUnread() {
    const lastReadIndex = this.messages.findIndex((message) => message.id === this.roomSelected.last_read_message_id);

    if (lastReadIndex === -1) return;
    const lastReadMessage = this.messages[lastReadIndex];

    if (!lastReadMessage) return;
    const lastReadElement = this.scrollMe.nativeElement.querySelector(`[data-id="${lastReadMessage.id}"]`);

    if (!lastReadElement) return;
    // this.scrollMe.nativeElement.scrollTop = lastReadElement.offsetTop;
    /* offsetTop - высчитывается сразу и не меняется (т.о. не учитывает динамических элементов. Напр добавление кнопки "читать далее")
     * дефект сдвига с offsetTop проявляется, только когда последнее непрочитанное сообщение имеет кнопку.
     * getBoundingClientRect - определяется в моменте и более точно определяет сдвиг */
    const elementRect = lastReadElement.getBoundingClientRect();
    const containerRect = this.scrollMe.nativeElement.getBoundingClientRect();

    const offset = elementRect.bottom - containerRect.top + this.scrollMe.nativeElement.scrollTop;
    this.scrollMe.nativeElement.scrollTop = offset;
  }

  private isScrolledToBottom(): boolean {
    const { scrollTop, scrollHeight, clientHeight } = this.scrollMe.nativeElement;
    return scrollTop + clientHeight >= scrollHeight - 10; // 10px погрешность от низа контейнера, с которой можно считать что скролл внизу.
  }

  updateMessages(room_id: string, messages: ChatMessage[]) {
    this.messages = JSON.parse(JSON.stringify(messages));
    this.count = this.chatService.getMessagesRoomStore(room_id).count;

    const tradeGroup = this.chatService.getGroups()[`dg-${this.roomSelected.group_id}`];

    this.messages.forEach((message) => {
      if (tradeGroup) {
        if (message.flags && message.flags.trade_provider && !tradeGroup.providers.includes(this.userId)) {
          message.author = {
            second_name: 'Поставщик',
          } as User;
        }

        if (message.flags && message.flags.trade_customer && !tradeGroup.customers.includes(this.userId)) {
          message.author = {
            second_name: 'Заказчик',
          } as User;
        }
      }

      // TODO: почему-то flags стало приходить пустое
      if (message.author.flags?.is_tso) {
        message.author.second_name = `${message.author.id}, ${message.author.second_name}`;
      }
    });
  }

  downloadFile(file: AttachedFile) {
    this.fileManagerService.downloadFile(file.url).subscribe((blob) => {
      saveAs(blob, `${file.filename}`);
    });
  }

  onRemoveMessage(message: ChatMessage) {
    this.chatDataService.deleteMessage(message.id);
  }

  onReplyMessage(message: ChatMessage) {
    // todo
  }

  onForwardMessage(message: ChatMessage) {
    // todo
  }

  protected isOverflowing(messageContent: HTMLElement) {
    return messageContent.scrollHeight > messageContent.clientHeight;
  }

  protected toggleExpand(message: ChatMessage) {
    if (this.expandedMessages.has(message.id)) {
      this.expandedMessages.delete(message.id);
    } else {
      this.expandedMessages.add(message.id);
    }
  }

  protected isExpanded(message: ChatMessage) {
    return this.expandedMessages.has(message.id);
  }

  protected trackById(_: number, message: ChatMessage) {
    return message.id;
  }

  private initializeIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) return;
          const messageId = Number(entry.target.getAttribute('data-id'));
          // console.log(`Message ${messageId} is visible`);

          const lastReadMessageId = this.roomSelected.last_read_message_id;

          if (lastReadMessageId == null || lastReadMessageId > messageId) return;
          this.markMessageAsRead(messageId);
        });
      },
      {
        root: this.scrollMe.nativeElement,
        threshold: 0.5,
      }
      /* threshold - % размеров объекта, который попал в область видимости контейнера
       * в нашем случае 0.5 - сообщение видимое на половину считается прочитанным
       * */
    );

    this.messageElements.changes.pipe(takeUntil(this.destroy$)).subscribe((elements: QueryList<ElementRef>) => {
      this.intersectionObserver.disconnect();

      elements.forEach((element) => {
        this.intersectionObserver.observe(element.nativeElement);
      });
    });
  }

  private markMessageAsRead(messageId: number) {
    this.unreadMessageIds.push(messageId);

    if (this.unreadMessageIds.length !== 1) return;
    this.messageReadSubject.next();
  }
}
