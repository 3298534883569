<div class="chat__tabs">
  <div
    (click)="scroll(-50)"
    class="chat__tab-toggle"
    [ngClass]="{ 'chat__tab-toggle--finish': !canScrollL() }"
    *ngIf="tabs.length"
  >
    <app-svg-icon [name]="'left'" width="8" height="16"></app-svg-icon>
  </div>
  <div
    #scrollMe
    class="chat__wrapper"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (mouseup)="onMouseOut($event)"
    (mouseleave)="onMouseOut($event)"
  >
    <app-chat-tab
      class="chat__tab"
      *ngFor="let tab of tabs"
      [tab]="tab"
      [active]="contactSelected.room_id === tab.room_id"
      [(isDragging)]="isDragging"
    ></app-chat-tab>
  </div>
  <div
    (click)="scroll(+50)"
    class="chat__tab-toggle"
    [ngClass]="{ 'chat__tab-toggle--finish': !canScrollR() }"
    *ngIf="tabs.length"
  >
    <app-svg-icon [name]="'right'" width="8" height="16"></app-svg-icon>
  </div>
  <div class="chat__tab-buttons ml-2">
    <button type="button" class="icon-button">
      <app-svg-icon name="union" width="16" height="16"></app-svg-icon>
    </button>
    <button type="button" class="icon-button">
      <app-svg-icon name="question-circle-color" width="16" height="16"></app-svg-icon>
    </button>
  </div>
</div>
